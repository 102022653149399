import { css } from "@styled-system/css";

export const baseStyles = css({
  gridAutoFlow: "column",
  gridAutoColumns: "min-content",
  alignSelf: "stretch",
  gridArea: "menu",
  gridRow: 1,
  display: "grid"
});

export const linkStyles = css({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
  position: "relative",
  whiteSpace: "nowrap",
  px: 4,
  fontSize: 3,
  lineHeight: 3,
  fontWeight: "medium",
  "&:hover": {
    color: "ink.main"
  },
  "&:focus": {
    borderRadius: "4px",
    outline: "none",
    boxShadow: `inset 0 0 0 2px transparent, inset 0 0 0 2px #017A67`
  }
});

export const linkResetStyleProps = css({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: "none"
  }
});
