/* eslint-disable */
// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React from "react";
import styled from "styled-components";

import { Box, Text, Icon, Count, Link } from "darker-matter";

import {
  IAnchorComponentProps,
  INavigation,
  INavItem
} from "darker-matter/build/shared-types";
import { baseStyles, linkResetStyleProps, linkStyles } from "./styles.ts";

const StyledMenu = styled(Box)(baseStyles);
const StyledNavItem = styled(Link)<
  INavItem & IAnchorComponentProps & { tabIndex: number }
>(linkResetStyleProps, linkStyles);

const Menu: React.FC<INavigation> = ({ activeItem, items, maxCount = 99 }) => {
  return (
    <StyledMenu as="ul">
      {items &&
        items.map((o, i) => {
          const active: boolean | undefined =
            (typeof activeItem === "string" && activeItem === o.id) ||
            (typeof activeItem === "number" && activeItem === i) ||
            (!activeItem && o.active);
          return (
            <Box
              as="li"
              key={i}
              display="inline-flex"
              boxShadow={active ? "menuActive" : null}
            >
              <StyledNavItem
                tabIndex={0}
                href={o.url}
                id={o.id}
                onClick={o.onClick}
                aria-label={o.ariaLabel}
                rel={o.external ? "noopener noreferrer" : null}
                target={o.external ? "_blank" : null}
                $color={active ? "ink.main" : "ink.light"}
              >
                {o.icon && (
                  <Icon
                    width="24px"
                    height="24px"
                    $color={active ? "primary.main" : "currentColor"}
                    name={active ? o.activeIcon : o.icon}
                    decorative
                  />
                )}
                <Text
                  textStyle="medium"
                  fontWeight="medium"
                  $color={active ? "ink.main" : "currentColor"}
                >
                  {o.label}
                </Text>
                {o.count && o.count > 0 ? (
                  <Count ml={2} value={o.count} maxValue={maxCount} />
                ) : null}
              </StyledNavItem>
            </Box>
          );
        })}
    </StyledMenu>
  );
};

export default Menu;
