import { Box, Text } from "darker-matter";
import { css } from "@styled-system/css";
import styled from "styled-components";
import { buttonMixin } from "shared/mixins/buttons";
import { Button } from "../Button/button.tsx";

export const ChatHeaderLabels = styled(Box)(
  css({
    display: "flex",
    alignItems: "center",
    gap: "24px",
    overflowX: "clip"
  })
);

export const ControlsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  margin-right: 24px;
  button {
    min-width: 48px;
    margin: 0;
    div {
      white-space: nowrap;
    }
  }

  #download-transcript-menu-button {
    height: 32px;
  }

  @media screen and (max-width: 640px) {
    flex-wrap: nowrap;
    gap: 8px;
    margin-right: 16px;
  }
`;

export const UserMenu = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    &:last-child {
      min-width: 40px;
      #user-menu-button {
        div {
          min-width: 40px;
        }
      }
    }
  }
`;

export const SessionButton = styled(Button)<{ $isStart: boolean }>`
  ${({ $isStart }) => buttonMixin($isStart ? "primary" : "error")}
`;

export const StyledStatus = styled(Text)<{
  $isOnline: boolean;
}>(({ $isOnline }) =>
  css({
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "10px",
    "&::before": {
      content: `""`,
      display: "block",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      mr: 1,
      bg: $isOnline ? "success.main" : "error.main"
    }
  })
);
