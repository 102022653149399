/* eslint-disable camelcase */
import { useCallback } from "react";
import useConfig from "../config/useConfig";

export function useVideoSessionUi(props) {
  const config = useConfig();
  const { appointment_id, auth_header, userName, isVideoSession } = props;
  const cacheBustRand = Math.floor(Math.random() * 100000000);
  const connectToCall = useCallback(() => {
    if (isVideoSession) {
      window.open(
        `${config.videoSessionUi_url}/Appointment/Chat/${appointment_id}?platform=pex&r=${cacheBustRand}`,
        "_blank"
      );
    }
  }, [appointment_id, auth_header, userName, isVideoSession, config]);

  return {
    connectToCall,
    usingVideo: isVideoSession,
    confirmingVideo: false,
    canConnect: isVideoSession
  };
}
