/* eslint-disable @typescript-eslint/no-explicit-any */
// Hook is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import { useEffect } from "react";

const checkIfRefContainsEventTarget = (
  ref: React.MutableRefObject<HTMLElement | null>,
  event: AnyEvent
): boolean => {
  if (!ref.current || ref.current.contains(event.target as Node)) {
    return true;
  }

  return false;
};

type AnyEvent = MouseEvent | TouchEvent | KeyboardEvent;

export const useOnClickOutside = (
  refs: React.MutableRefObject<HTMLElement | null>[],
  handler: (event: AnyEvent) => void
) => {
  useEffect(() => {
    const listener = (event: any) => {
      const refCheck = refs.some((ref) => {
        return checkIfRefContainsEventTarget(ref, event);
      });
      if (refCheck) return;
      handler(event);
    };

    const onWindowBlur = (event: any) => {
      handler(event);
    };

    if (document) {
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      document.addEventListener("visibilitychange", listener);
    }

    if (window) {
      window.addEventListener("blur", onWindowBlur);
    }

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("visibilitychange", listener);
      window.removeEventListener("blur", onWindowBlur);
    };
  }, [refs, handler]);
};

// Type assetion for event.target https://stackoverflow.com/questions/43842057/detect-if-click-was-inside-react-component-or-not-in-typescript

export default useOnClickOutside;
