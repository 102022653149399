/* eslint-disable @typescript-eslint/no-explicit-any */
import { variant } from "styled-system";
import { rem } from "polished";
import { css } from "@styled-system/css";
import styled from "styled-components";
import shouldForwardProp from "styles/shouldForwardProp";
import { coreStyleFeatures } from "styles/shared-style-props";

const darkOnHover = ["ink"];
const lightOnFocus = ["error"];
export const baseStyles = (props: any) =>
  css({
    appearance: "none",
    border: "0 none",
    display: "inline-flex",
    justifyContent: "center",
    fontWeight: "medium",
    textAlign: "center",
    alignItems: "center",
    transition: "0.2s",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background, color",
    fontFamily: "body",
    userSelect: "none",
    verticalAlign: "middle",
    width: props.fullWidth ? "100%" : "auto",

    "&:disabled": {
      pointerEvents: "none"
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: darkOnHover.includes(props.color)
        ? `${props.color}.dark`
        : `${props.color}.light`
    },
    "&:active": {
      backgroundColor: `${props.color}.dark`
    },
    "&:focus": {
      outline: "none",
      backgroundColor: lightOnFocus.includes(props.color)
        ? `${props.color}.light`
        : `${props.color}.main`,
      boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${
        props.color === "error" ? "#C83232" : "#017A67"
      }`
    }
  });

export const appearanceVariant = variant({
  prop: "appearance",
  variants: {
    primary: {
      bg: "actionPrimary.rest",
      color: "actionPrimary.text",
      "&:hover": {
        bg: "actionPrimary.hover"
      },
      "&:active": {
        bg: "actionPrimary.pressed"
      },
      "&:disabled": {
        bg: "actionPrimary.disabled"
      }
    },
    secondary: {
      bg: "actionSecondary.rest",
      color: "actionSecondary.text",
      "&:hover": {
        bg: "actionSecondary.hover"
      },
      "&:active": {
        bg: "actionSecondary.pressed"
      },
      "&:disabled": {
        bg: "actionSecondary.disabled"
      }
    },
    tertiary: {
      bg: "actionTertiary.rest",
      color: "actionTertiary.text",
      textDecoration: "underline",
      "&:hover": {
        bg: "actionTertiary.hover"
      },
      "&:active": {
        bg: "actionTertiary.pressed"
      },
      "&:disabled": {
        bg: "actionTertiary.disabled",
        textDecoration: "none"
      }
    },
    "tertiary-muted": {
      bg: "actionTertiary.rest",
      color: "link.muted",
      textDecoration: "underline",
      "&:hover": {
        bg: "actionTertiary.hover"
      },
      "&:active": {
        bg: "actionTertiary.pressed"
      },
      "&:disabled": {
        bg: "actionTertiary.disabled",
        textDecoration: "none"
      }
    },
    link: {
      bg: "transparent",
      color: "link.main",
      textDecoration: "underline",
      "&:disabled": {
        color: "ink.lighter",
        textDecoration: "none"
      },
      padding: "6px 10px",
      borderRadius: "100px",
      backgroundColor: `inherit`,
      "&:hover": {
        backgroundColor: `neutral.light`
      },
      "&:active": {
        backgroundColor: `neutral.dark`
      },
      "&:focus": {
        backgroundColor: `neutral.main`,
        boxShadow: `0 0 0 2px #fff, 0 0 0 4px #017A67`
      },
      transition: "background-color 0.2s"
    },
    "link-muted": {
      bg: "transparent",
      color: "link.muted",
      textDecoration: "underline",

      "&:hover": {
        cursor: "default",
        boxShadow: "none"
      },

      "&:disabled": {
        textDecoration: "none"
      }
    },
    destructive: {
      bg: "actionDestructive.rest",
      color: "actionDestructive.text",
      "&:hover": {
        bg: "actionDestructive.hover"
      },
      "&:active": {
        bg: "actionDestructive.pressed"
      },
      "&:disabled": {
        bg: "actionDestructive.disabled"
      }
    }
  }
});

export const segmentVariant = variant({
  prop: "segment",
  variants: {
    first: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: "999em",
      borderBottomLeftRadius: "999em"
    },
    intermediate: {
      borderRadius: 0
    },
    last: {
      borderTopRightRadius: "999em",
      borderBottomRightRadius: "999em",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    none: {
      borderRadius: "999em"
    }
  }
});

export const magnitudeVariant = variant({
  prop: "magnitude",
  variants: {
    small: {
      fontSize: 2,
      lineHeight: 2,
      px: 3,
      py: 2
    },
    medium: {
      fontSize: 3,
      lineHeight: 3,
      minHeight: rem(40),
      px: 5,
      py: 3
    },
    large: {
      fontSize: 4,
      lineHeight: 4,
      minHeight: rem(48),
      px: 5,
      py: 3
    }
  }
});

export const StyledButton = styled("button").withConfig({
  shouldForwardProp: (prop) =>
    !["magnitude", "segment", "appearance", "fullWidth"].includes(prop) &&
    shouldForwardProp(prop)
})<any>(
  baseStyles,
  appearanceVariant,
  segmentVariant,
  magnitudeVariant,
  coreStyleFeatures
);
