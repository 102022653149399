import axios from "axios";

import { getBearerAuthHeader, getMyId, getTelemetry } from "Identity/selectors";
import { getAppointmentId } from "store/chatendpoints/selectors";
import {
  TECHNICAL_DIFFICULTIES,
  JOIN_APPOINTMENT,
  USER_OFFLINE
} from "../chatconnection/types";
import { POST_ERROR_TO_ELASTIC } from "./actions";

const HOUSTON_URL = global.__ieso_config.houston_url;

function getErrorData(getState, action) {
  return {
    identity_guid: getMyId(getState()),
    session_guid: getAppointmentId(getState()),
    message: action.value
  };
}

function logInfoToHouston(getState, action) {
  const telemetry = getTelemetry(getState());
  const bearerAuthHeader = getBearerAuthHeader(getState());
  if (!bearerAuthHeader) {
    return;
  }
  const infoConfig = {
    method: "post",
    url: `${HOUSTON_URL}/log/info`,
    headers: {
      "content-type": "application/json",
      Authorization: getBearerAuthHeader(getState())
    },
    crossDomain: true,
    data: {
      identity_guid: getMyId(getState()),
      session_guid: getState().chatendpoints.appointment_id,
      message: action.value,
      event_type: action.type
    }
  };
  axios(infoConfig).catch((error) => {
    telemetry.trackError(error);
  });
}

function logWarningToHouston(getState, action) {
  const telemetry = getTelemetry(getState());
  const bearerAuthHeader = getBearerAuthHeader(getState());
  if (!bearerAuthHeader) {
    return;
  }
  const warnConfig = {
    method: "post",
    url: `${HOUSTON_URL}/log/warnings`,
    headers: {
      "content-type": "application/json",
      Authorization: getBearerAuthHeader(getState())
    },
    crossDomain: true,
    data: {
      identity_guid: getMyId(getState()),
      session_guid: getAppointmentId(getState()),
      message: action.value,
      event_type: action.type
    }
  };
  axios(warnConfig).catch((error) => {
    telemetry.trackError(error);
  });
}

function logErrorToHouston(getState, action) {
  const telemetry = getTelemetry(getState());
  const bearerAuthHeader = getBearerAuthHeader(getState());
  if (!bearerAuthHeader) {
    return;
  }
  const errorConfig = {
    method: "post",
    url: `${HOUSTON_URL}/log/errors`,
    headers: {
      "content-type": "application/json",
      Authorization: getBearerAuthHeader(getState())
    },
    crossDomain: true,
    data: getErrorData(getState, action)
  };
  axios(errorConfig).catch((error) => {
    telemetry.trackError(error);
  });
}

export const loggingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case JOIN_APPOINTMENT:
      logInfoToHouston(store.getState, action);
      break;

    case "log_error":
    case POST_ERROR_TO_ELASTIC:
    case TECHNICAL_DIFFICULTIES:
      logErrorToHouston(store.getState, action);
      break;

    case USER_OFFLINE:
      logWarningToHouston(store.getState, action);
      break;

    default:
      break;
  }

  return next(action);
};
