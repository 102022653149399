// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React, { useState } from "react";
import styled from "styled-components";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import usePortal from "react-cool-portal";

import { Box } from "darker-matter";

import { IBoxComponentProps } from "darker-matter/build/shared-types";

import { containerStyles, popoverStyles, popperStyles } from "./styles.ts";

interface IPopoverProps extends IBoxComponentProps {
  /**
   * Mandatory - used to tie the trigger to the popover element
   */
  id: string;
  /**
   * Trigger should be a component or DOM element; onclick events will be automatically attached to the item.
   */
  trigger: React.ReactElement;
  /**
   * Pass the content to be displayed in the popover as child content.
   */
  children: React.ReactElement;
  /**
   * Placement allows you to set a positioning preference for the popover.
   * Positioning will be automatically adjusted if the viewport prevents the popover from being visible.
   * More information [here](https://popper.js.org/).
   * Default is `"auto"`.
   */
  placement?: Placement;
  /**
   * Pass an array of two numbers representing the skid (offset along the placement axis) and
   * distance [offset values](https://popper.js.org/docs/v2/modifiers/offset/).
   */
  offset?: [number, number];
  ariaLabel?: string;
  ariaDescription?: string;
}

export const StyledPopper = styled("div")(popperStyles);
export const StyledPopover = styled(Box)(popoverStyles);
export const StyledContainer = styled(Box)(containerStyles);

const usePopover = (containerId = "portal-popover") => {
  const { Portal, isShow, toggle, show, hide } = usePortal({
    containerId,
    defaultShow: false,
    internalShowHide: false
  });

  /**
   * Popover is a utility component for placing content in frame which is displayed when a trigger item is clicked. It uses [React Popper](https://popper.js.org/react-popper/) a wrapper on Popper JS to handle core functionality, and the [React Cool Portal](https://github.com/wellyshen/react-cool-portal) hook to project the content into a root DOM location.
   */
  const Popover: React.FC<IPopoverProps> = ({
    id,
    trigger,
    ariaLabel,
    ariaDescription,
    children,
    placement = "bottom-start",
    offset = [0, 1],
    ...props
  }) => {
    const [
      referenceElement,
      setReferenceElement
    ] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );

    const config = [];
    if (offset && offset.length === 2) {
      config.push({
        name: "offset",
        enabled: true,
        options: {
          offset
        }
      });
    }

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: config,
      strategy: "absolute"
    });

    const triggerEl = React.cloneElement(trigger, {
      id: `${id}-button`,
      onClick: toggle,
      "aria-expanded": isShow,
      "aria-controls": `${id}-popover`,
      "aria-haspopup": true,
      "aria-describedby": ariaDescription,
      "aria-label": ariaLabel,
      role: "button"
    });
    return (
      <StyledContainer {...props}>
        <div ref={setReferenceElement}>{triggerEl}</div>
        {isShow && (
          <Portal>
            <StyledPopper
              ref={setPopperElement}
              id={`${id}-popover`}
              style={styles.popper}
              {...attributes.popper}
            >
              <StyledPopover role="dialog" aria-modal="false">
                {children}
              </StyledPopover>
            </StyledPopper>
          </Portal>
        )}
      </StyledContainer>
    );
  };

  Popover.displayName = "Popover";
  Popover.defaultProps = {
    placement: "auto"
  };

  return { Popover, hide, show, isShow };
};

export default usePopover;
