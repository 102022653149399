import { FC, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from "react-idle-timer";
import {
  COOKIE_KEYS,
  createCookie,
  deleteCookie,
  listenCookieChange,
  readCookie
} from "utils/cookies";
import { useUserId } from "../../hooks/useUserId.ts";

const DEFAULT_IDLE_TIMEOUT_MS = 1000 * 60 * 20; // 20 minutes

interface IdleDetectorProps {
  sessionTimeout: number;
}

export const IdleDetector: FC<IdleDetectorProps> = ({
  sessionTimeout = DEFAULT_IDLE_TIMEOUT_MS
}) => {
  const { logout } = useAuth0();
  const userId = useUserId();

  const handleIdle = () => {
    localStorage.setItem("retry", "0");
    logout().then(() => {
      deleteCookie(COOKIE_KEYS.iesoCookieTimeout);
    });
  };

  const handleAction = () => {
    createCookie(COOKIE_KEYS.iesoCookieTimeout, Date.now());
  };

  const { start } = useIdleTimer({
    name: `idle-timer-therapy-session-ui`,
    element: document,
    eventsThrottle: 1000,
    timeout: sessionTimeout,
    onAction: handleAction,
    onIdle: handleIdle
  });

  useEffect(() => {
    const intervalId = listenCookieChange(({ oldValue }) => {
      const newCookieTimeout = readCookie(COOKIE_KEYS.iesoCookieTimeout);
      const oldCookieTimeout = readCookie(
        COOKIE_KEYS.iesoCookieTimeout,
        oldValue
      );

      if (newCookieTimeout) {
        if (oldCookieTimeout !== newCookieTimeout) {
          start();
        }
      } else if (oldCookieTimeout) {
        localStorage.setItem("retry", "0");
        logout();
      }
    });

    return () => {
      clearInterval(intervalId);
    };
  }, [logout, start, userId]);

  return null;
};
