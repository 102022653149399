import { IMessage, IParticipant } from "types/chatTypes.ts";
import { Box, DateUtils, Spinner, Utterance } from "darker-matter";
import React, { useState } from "react";
import parser from "html-react-parser";
import {
  useAddDeletedEventApi,
  useRemoveDeletedEventApi
} from "api/deletedEvents/useDeletedEventsApi.ts";
import { ReactComponent as DeleteIcon } from "shared/icons/delete.svg";
import { showToast } from "../../Toast/Toast.tsx";
import useAttachments from "../../ChatTextbox/hooks/useAttachments.ts";
import MessageAttachment from "./MessageAttachment.tsx";
import { StyledBubble } from "../styles.ts";
import { useModal } from "../ModalContext.tsx";
import { DeleteButton, MessageContainer } from "./styles.ts";

interface IMessageProps {
  message: IMessage;
  nextMessage: IMessage | null;
  isSessionOwner: boolean;
  sessionId: string;
  allParticipants: IParticipant[];
  isSysAdmin: boolean;
  disabled?: boolean;
}

const REMOVED_MESSAGE = "This message was deleted";

const Message: React.FC<IMessageProps> = ({
  message,
  nextMessage,
  sessionId,
  isSessionOwner,
  allParticipants,
  isSysAdmin,
  disabled
}) => {
  const { downloadAttachment } = useAttachments(sessionId);
  const [isRemoved, setIsRemoved] = useState<boolean>(
    message.deletedUtc != null
  );
  const { openModal, closeModal } = useModal();

  const removeDeletedEvent = useRemoveDeletedEventApi(message.event_id);

  const handleDeleteSuccess = () => {
    setIsRemoved(true);
    showToast({
      appearance: "success",
      message: "Utterance removed",
      buttonText: "Undo",
      onClick: () =>
        removeDeletedEvent.mutateAsync().then(() => setIsRemoved(false))
    });
  };

  const handleDeleteError = () => {
    showToast({
      appearance: "error",
      message: "Unable to remove utterance"
    });
  };

  const addDeletedEvent = useAddDeletedEventApi(
    message.event_id,
    handleDeleteSuccess,
    handleDeleteError
  );

  const handleClick = () => {
    addDeletedEvent.mutateAsync();
    closeModal();
  };

  const messageSender = allParticipants.find(
    (p) => p.identity_guid === message.identity_guid
  );
  const appearance = messageSender.is_owner ? "therapist" : "member";
  const bubbleAppearance = messageSender.is_owner ? "default" : "me";
  const orientation =
    (isSessionOwner === false && !messageSender.is_owner) ||
    (isSessionOwner !== false && messageSender.is_owner)
      ? "right"
      : "left";
  const author = messageSender.fullname;
  const timestamp = DateUtils.formatTime(new Date(message.time_utc), "short");
  const hideAvatar =
    nextMessage?.identity_guid === message.identity_guid &&
    nextMessage?.event_type === "Sentence";
  const messageContent = isRemoved
    ? REMOVED_MESSAGE
    : parser(message.sanitizedContent);
  const isEmptyMessageContent =
    Array.isArray(messageContent) && messageContent.length === 0;

  return (
    <React.Fragment key={message.event_id}>
      <Utterance
        appearance={appearance}
        orientation={orientation}
        status={message.transit_status || "sent"}
        author={author}
        hideAvatar={hideAvatar}
        timestamp={message.event_type !== "typing" ? timestamp : null}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={orientation === "right" ? "flex-end" : "flex-start"}
        >
          {!isEmptyMessageContent && (
            <MessageContainer>
              <StyledBubble
                orientation={orientation}
                appearance={bubbleAppearance}
              >
                {message.event_type === "typing" ? (
                  <Spinner />
                ) : (
                  <>{messageContent}</>
                )}
              </StyledBubble>
              {isSysAdmin && messageSender.is_owner && !isRemoved && (
                <Box display="flex" alignItems="center" ml="2">
                  <DeleteButton
                    id="delete-message"
                    disabled={disabled}
                    onClick={() =>
                      openModal({ messageContent, onProceed: handleClick })
                    }
                  >
                    <DeleteIcon
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </DeleteButton>
                </Box>
              )}
            </MessageContainer>
          )}
          {!!message.attachments?.length && (
            <Box mt={2}>
              {message.attachments?.map((a) => (
                <MessageAttachment
                  key={a.id}
                  attachment={a}
                  disabled={disabled}
                  onDownloadAttachment={downloadAttachment}
                />
              ))}
            </Box>
          )}
        </Box>
      </Utterance>
    </React.Fragment>
  );
};

function areEqual(prevProps: IMessageProps, nextProps: IMessageProps) {
  return (
    prevProps.message.event_guid === nextProps.message.event_guid &&
    prevProps.nextMessage?.event_guid === nextProps.nextMessage?.event_guid &&
    prevProps.isSessionOwner === nextProps.isSessionOwner &&
    prevProps.sessionId === nextProps.sessionId &&
    prevProps.message.transit_status === nextProps.message.transit_status &&
    prevProps.disabled === nextProps.disabled
  );
}

export default React.memo(Message, areEqual);
