import React from "react";
import styled from "styled-components";

import css from "@styled-system/css";
import { rem } from "polished";
import { Box, IBoxComponentProps, Icon, ValidIcon, Text } from "darker-matter";
import {
  baseStyles,
  contentStyles,
  ellipsisStyles,
  fileTypeContainerStyles
} from "./styles.ts";
import { coreStyleFeatures } from "./coreStyleFeatures.ts";

export interface IAttachmentProps extends IBoxComponentProps {
  /** The MIME type (if known) of the attachment. */
  fileType?: string;
  /** The name of the attached file. */
  fileName: string;
  /**
   * Indicates whether the attachment is currently loading. Default is `false`.
   */
  loading?: boolean;
  /**
   * Color to  use muted visual state.
   */
  readonly muted?: boolean;
  /**
   * Text to appear in the heading
   */
  readonly title?: string;
  /**
   * Actions to display inside the Attachment
   */
  readonly children?: React.ReactNode | React.ReactNode[];
}

const StyledAttachmentContainer = styled(Box)(
  coreStyleFeatures,
  css({
    overflow: "hidden"
  })
);

const StyledAttachment = styled(Box).withConfig({
  shouldForwardProp: (name: string): boolean => !["muted"].includes(name)
})<Omit<IAttachmentProps, "fileName" | "fileType">>(
  baseStyles,
  coreStyleFeatures
);

const StyledFileTypeContainer = styled(Box)(fileTypeContainerStyles);

const StyledContent = styled.div(contentStyles, ellipsisStyles);

const TruncatedText = styled(Text)(ellipsisStyles);

function getFileIcon(fileType: string): ValidIcon {
  if (fileType?.includes("image")) {
    return "image";
  }

  if (fileType?.includes("audio")) {
    return "speaker";
  }

  if (fileType?.includes("video")) {
    return "video";
  }

  return "document";
}

export const Attachment: React.FC<IAttachmentProps> = ({
  id,
  children,
  title,
  fileName,
  fileType,
  muted = false,
  borderRadius = "xl",
  width = rem(310),
  ...props
}) => (
  <StyledAttachmentContainer
    borderRadius={borderRadius}
    width={width}
    {...props}
  >
    <StyledAttachment id={id} role="document" muted={muted}>
      <StyledFileTypeContainer
        px={title ? rem(12) : rem(6)}
        minWidth={title ? rem(48) : rem(28)}
      >
        <Icon
          height={title ? 24 : 16}
          width={title ? 24 : 16}
          name={getFileIcon(fileType)}
          decorative
        />
      </StyledFileTypeContainer>
      <StyledContent>
        {title && (
          <TruncatedText title={title} textStyle="small" mb={1}>
            {title}
          </TruncatedText>
        )}
        <TruncatedText title={fileName} fontWeight="bold">
          {fileName}
        </TruncatedText>
        {children && <Box mt={title ? 2 : 0}>{children}</Box>}
      </StyledContent>
    </StyledAttachment>
  </StyledAttachmentContainer>
);

Attachment.displayName = "Attachment";

export default Attachment;
