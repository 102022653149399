import { connect } from "react-redux";
import {
  getConnectionStatus,
  shouldShowConnectionStatus as shouldShowConnectionStatusImported,
  shouldShowReconnectButton,
  isReconnected as isReconnectedImported,
  getSessionStatus
} from "store/chatconnection/selectors";
import { USER_RECONNECT } from "store/chatconnection/types";
import ConnectionStatus from "./ConnectionStatus.tsx";

function mapStateToProps(state) {
  const connectionStatus = getConnectionStatus(state);

  const shouldShowConnectionStatus =
    shouldShowConnectionStatusImported(state) && connectionStatus !== "None";
  const showReconnectButton = shouldShowReconnectButton(state);
  const isReconnected = isReconnectedImported(state);

  const sessionStatus = getSessionStatus(state);

  return {
    connectionStatus,
    shouldShowConnectionStatus,
    showReconnectButton,
    isReconnected,
    sessionStatus
  };
}

const mapDispatchToProps = (dispatch) => ({
  onReconnectClick: () => dispatch({ type: USER_RECONNECT })
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
