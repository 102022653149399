/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text, Modal } from "darker-matter";
import styled from "styled-components";
import { css } from "@styled-system/css";
import CoreIconButton from "components/IconButton/iconButton.tsx";
import { buttonMixin } from "shared/mixins/buttons.tsx";
import { Button } from "../Button/button.tsx";

export const CoreModal = styled(Modal)`
  div[role="dialog"] {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 40px 48px;
    & > div {
      min-height: auto;
      margin: 0;
      padding: 0;
    }
    footer {
      padding: 0;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  }
`;

export const Header = styled(Text)<{
  $contentGap?: number;
  $justifyContent?: string;
  $fontWeight?: number;
}>(
  ({
    $contentGap = 32,
    $justifyContent = "space-between",
    $fontWeight = 400
  }) =>
    css({
      display: "flex",
      alignItems: "center",
      justifyContent: `${$justifyContent}`,
      paddingBottom: `${$contentGap}px`,
      fontSize: "28px",
      "@media screen and (max-width: 640px)": {
        fontSize: "24px"
      },
      fontWeight: `${$fontWeight}`
    })
);

export const IconButton = styled(CoreIconButton)(
  css({
    minWidth: "48px",
    minHeight: "48px",
    ml: 6,
    "@media screen and (max-width: 640px)": {
      ml: 4
    }
  })
);

export const PrimaryButton = styled(Button)<{ $color: any }>`
  ${({ $color }) => buttonMixin($color)}
`;
