import React, { FC, useState, useEffect } from "react";
import { Box } from "darker-matter";
import { JoinBtn } from "./styles.ts";
import { Modal } from "../../Modal/Modal.tsx";

interface IJoinButtonProps {
  connectToCall: () => void;
  mobileView: boolean;
  disabled: boolean;
  isConsentAccepted: boolean;
  loading?: boolean;
  onAccept: () => void;
}

export const JoinButton: FC<IJoinButtonProps> = ({
  connectToCall,
  mobileView,
  disabled,
  isConsentAccepted,
  loading,
  onAccept
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (isConsentAccepted) {
      setShowConfirmationModal(false);
    }
  }, [isConsentAccepted]);

  const onClickVideoButton = () => {
    if (isConsentAccepted) {
      connectToCall();
    } else {
      setShowConfirmationModal(true);
    }
  };

  return (
    <>
      <Modal
        maxWidth="696px"
        showModal={showConfirmationModal}
        headerContent="Your session forms part of your health record"
        buttonLabel="Continue"
        buttonAppearance="primary"
        onClose={() => setShowConfirmationModal(false)}
        dismissable={false}
        onProceed={onAccept}
        loading={loading}
      >
        <Box mb={6}>
          An audio recording of your sessions will be securely stored as part of
          your health record. Please see our Privacy Notices for more
          information.
        </Box>
      </Modal>

      <JoinBtn
        ariaLabel="Connect to video"
        ariaDescription="Connect to video session"
        icon="video"
        magnitude="small"
        onClick={onClickVideoButton}
        disabled={disabled}
      >
        {mobileView ? "Join" : "Connect to video"}
      </JoinBtn>
    </>
  );
};

export default JoinButton;
