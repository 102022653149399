import { css } from "@styled-system/css";

export const userMenuStyles = (appearance: string) =>
  css({
    gridArea: "altMenus",
    div: {
      cursor: "pointer"
    },
    "&:hover > div": {
      backgroundColor:
        appearance === "member" ? "extended3.light" : "extended1.light"
    },
    "&:active > div": {
      backgroundColor:
        appearance === "member" ? "extended3.dark" : "extended1.dark"
    },
    "&:focus > div": {
      boxShadow: "0 0 0 2px #fff, 0 0 0 4px #017A67"
    },
    "&:focus": {
      borderRadius: "50%",
      boxShadow: `0 0 0 2px transparent, 0 0 0 2px #017A67`
    }
  });
