import { createContext, useContext, useMemo, useState } from "react";

export const AppointmentDetails = createContext(null);
export const useAppointmentDetailsContext = () =>
  useContext(AppointmentDetails);

export function useCreateAppointmentDetailsContext() {
  const [appointmentId, setAppointmentId] = useState(null);

  return useMemo(
    () => ({
      appointmentId,
      setAppointmentId
    }),
    [appointmentId, setAppointmentId]
  );
}
