import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";
import throttleNotify from "utils/throttleNotify";

interface DateChipProps {
  intersectingRefs: RefObject<Record<string, HTMLDivElement>>;
  containerRef: RefObject<HTMLDivElement>;
}

const Chip = styled.div(({ $empty }: { $empty: boolean }) =>
  css({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    padding: "4px 16px",
    borderRadius: "100px",
    backgroundColor: "neutral.main",
    color: "ink.light",
    opacity: $empty ? 0 : 1,
    position: "sticky",
    top: "16px",
    left: "50%",
    zIndex: $empty ? -1 : 1,
    transform: "translateX(-50%)",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 4px 0px rgba(0, 0, 0, 0.05)",
    transition: "opacity 0.2s"
  })
);

const OFFSET_TOP = 16;
const CHIP_OFFSET = 40;

const DateChip: FC<DateChipProps> = ({ containerRef, intersectingRefs }) => {
  const [intersectingText, setIntersectingText] = useState("");
  const chipRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout>(null);

  const calcDataPosition = () => {
    const chipRect = chipRef.current.getBoundingClientRect();
    const timeStampsRefs: HTMLDivElement[] = Object.values(
      intersectingRefs.current || {}
    );
    const intersectingElement = timeStampsRefs.reverse().find((ref) => {
      const rect = ref.getBoundingClientRect();
      return chipRect.bottom + CHIP_OFFSET > rect.bottom;
    });
    const date = intersectingElement?.textContent;
    if (date !== intersectingText) {
      const container = containerRef.current;
      const hideChip = container.scrollTop <= OFFSET_TOP;
      setIntersectingText(hideChip ? "" : intersectingElement?.textContent);
    }
  };

  const handleScroll = () => {
    calcDataPosition();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(calcDataPosition, 100);
    }
  };

  const handleScrollThrottled = useCallback(
    throttleNotify(calcDataPosition, 20),
    []
  );

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      handleScroll();
      container.addEventListener("scroll", handleScrollThrottled);
    }
    return () => {
      container &&
        container.removeEventListener("scroll", handleScrollThrottled);
    };
  }, [containerRef]);

  return (
    <Chip ref={chipRef} $empty={!intersectingText}>
      {intersectingText}
    </Chip>
  );
};

export default DateChip;
