import React from "react";
import { Text } from "dark-matter";
import { useModal } from "./ModalContext.tsx";
import { Modal } from "../Modal/Modal.tsx";
import { StyledText } from "./styles.ts";

export const DeleteMessageModal = () => {
  const { showModal, closeModal, modalContent } = useModal();

  return (
    <Modal
      showModal={showModal}
      headerContent="Do you want to remove this message?"
      buttonAppearance="destructive"
      buttonLabel="Remove message"
      onCancel={closeModal}
      onProceed={modalContent?.onProceed}
    >
      <Text>You are about to remove the message.</Text>
      <br />
      <StyledText textStyle="small">
        {`"`}
        {modalContent?.messageContent}
        {`"`}
      </StyledText>
      <br />
      <Text>
        Please verify the correct message has been selected as there is no way
        to undo this action.
      </Text>
      <br />
    </Modal>
  );
};
