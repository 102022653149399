import loadConfig from "config";

const getConfig = async () => loadConfig;

const userConsentApiUrls = {
  getContentStatus: () =>
    getConfig().then((config) => `${config.cdp_api_url}/users-consent/me`),
  updateContentStatus: (policyName) =>
    getConfig().then(
      (config) =>
        `${config.cdp_api_url}/users-consent/me/${policyName}/acknowledge`
    )
};

export default userConsentApiUrls;
