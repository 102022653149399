export const getAppointmentId = (state) =>
  state.chatendpoints && state.chatendpoints.appointment_id;

export const getMessageHistory = (state) => state.chatendpoints.message_history;
export const getParticipants = (state) => state.chatendpoints.getParticipants;

export const getHealthServiceLogoUrl = (state) =>
  getProviderInfoOrUndefined(state) &&
  getProviderInfoOrUndefined(state).health_service_logo_url;

export const getProviderName = (state) =>
  (getProviderInfoOrUndefined(state) &&
    getProviderInfoOrUndefined(state).provider_name) ||
  undefined;

export const getPrimaryHost = (state) =>
  (getProviderInfoOrUndefined(state) &&
    getProviderInfoOrUndefined(state).provider_primary_hostname) ||
  undefined;

export const getProviderBaseUrl = (state) =>
  (getPrimaryHost(state) && `https://${getPrimaryHost(state)}`) || undefined;

export const getCaseHash = (state) =>
  state.chatendpoints.appointment_data.case_hash;

export const getOtherIds = (myId, state) =>
  Object.keys(state.chatendpoints.participants).filter(
    (participantKey) => participantKey !== myId
  );

export const getOtherParticipantsRoleHash = (otherId, state) =>
  (state.chatendpoints.participants &&
    state.chatendpoints.participants[otherId] &&
    state.chatendpoints.participants[otherId].role_hash) ||
  undefined;

function getProviderInfoOrUndefined(state) {
  return (
    (state && state.chatendpoints && state.chatendpoints.providerInfo) ||
    undefined
  );
}
