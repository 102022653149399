import styled from "styled-components";
import { css } from "@styled-system/css";
import { linkButtonMixin } from "shared/mixins/buttons";
import Button from "components/Button";

export const AttachmentButton = styled(Button)(
  linkButtonMixin,
  css({
    "&:first-child": {
      marginLeft: "-2px"
    }
  })
);

export const DeleteButton = styled(Button)`
  padding: 2px;
  opacity: 0;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;
