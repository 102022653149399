/* eslint-disable */
// *** ACTIONS ***
// This file contains all the action creator functions, try to keep
// business logic in the action creators or where necessary, middlewares.
// Thick action creators thin reducers. Is the general pattern.

// actions has a type: string, payload: data
import axios from "axios";

import {
  connectToSignalR,
  getSessionInfo,
  getAtlasSessionInfo,
  addParticipantToSession,
  viewSessionAsSupervisor
} from "store/chatconnection/actions";
import { getSessionTranscript } from "store/therapyservice/actions";
// import { types as questionnaires_types } from "store/questionnaires/types";
// import { types as recoveryIndicatorTypes } from "store/recoveryindicator/types";
// import { types as clinicalstudyTypes } from "store/clinicalstudy/types";
import { getBearerAuthHeader } from "Identity/selectors";
// import { getAppointmentId } from "store/chatendpoints/selectors";
import { types as t } from "./types";

const FUNC_URL = "";

export function getHeaders(getState) {
  return {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: getBearerAuthHeader(getState())
  };
}

// APPOINTMENT actions

// set the page title when provider received
const setPageTitle = (title) => (document.title = title);

export function setAppointment(appointmentId) {
  return (dispatch) => {
    dispatch({
      type: t.CHAT_SET_APPOINTMENT_ID,
      value: appointmentId
    });
    return Promise.all([
      new Promise(() => dispatch(connectToSignalR())),
      // new Promise(() => dispatch(getAppointmentInfo())),
      new Promise(() => dispatch(getSessionInfo()))
    ]);
  };
}

export function setReferralID(referralId) {
  return (dispatch) => {
    new Promise(() => dispatch(getAtlasSessionInfo(referralId)));
  };
}

export function addParticipant(referralId) {
  return (dispatch) =>
    new Promise(() => dispatch(addParticipantToSession(referralId)));
}

export function viewAsSupervisor(referralId) {
  return (dispatch) =>
    new Promise(() => dispatch(viewSessionAsSupervisor(referralId)));
}

export default {
  setAppointment,
  setReferralID,
  getSessionTranscript
};
