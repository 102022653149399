import { connect } from "react-redux";
import { getSessionAvailability } from "store/chatconnection/selectors";
import { SET_ADDING_PARTICIPANT_TO_SESSION_STATE } from "store/chatconnection/types";
import { addParticipant, viewAsSupervisor } from "store/chatendpoints/actions";
import RestrictedSessionView from "./RestrictedChatView.tsx";
import { states } from "./states.tsx";

function mapStateToProps(state) {
  const sessionAvailability = getSessionAvailability(state);
  const stateData = states[sessionAvailability] || {};

  return {
    stateData
  };
}

const mapDispatchToProps = (dispatch) => ({
  addParticipant: (referralId) => {
    dispatch({
      type: SET_ADDING_PARTICIPANT_TO_SESSION_STATE,
      data: { loading: true }
    });
    dispatch(addParticipant(referralId));
  },
  viewAsSupervisor: (referralId) => {
    dispatch(viewAsSupervisor(referralId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedSessionView);
