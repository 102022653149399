import React from "react";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import useConfig from "config/useConfig";
import { IMessage, IParticipant } from "types/chatTypes.ts";
import { deleteCookie, COOKIE_KEYS } from "utils/cookies.ts";
import { IActionListSection } from "../ActionList/types.ts";
import Banner from "./components/Banner.tsx";

export const useMenuItems = (admin: boolean): IActionListSection[] => {
  const { logout } = useAuth0();
  const config = useConfig();

  const myPatientItem = {
    id: "my-patients-menu-item",
    label: "My patients",
    ariaLabel: "My patients",
    ariaDescription: "Link to my patients page",
    onClick: () => window.open(`${config.cdp_admin_url}/myPatients/`, "_blank")
  };

  const homeItem = {
    id: "home-menu-item",
    label: "Home",
    ariaLabel: "Home",
    ariaDescription: "Link to home page",
    onClick: () => window.open(config.cdp_patient_url)
  };

  const myAccountItem = {
    id: "my-account-menu-item",
    label: "My account",
    ariaLabel: "My account",
    ariaDescription: "Link to my account page",
    onClick: () =>
      window.open(
        admin
          ? `${config.cdp_admin_url}/myAccount/`
          : `${config.cdp_patient_url}/my-account/`,
        "_blank"
      )
  };

  const privacyNoticesItem = {
    id: "privacy-notice-menu-item",
    label: "Privacy notices",
    ariaLabel: "Privacy notices",
    ariaDescription: "Link to privacy notices page",
    onClick: () =>
      window.open(
        "https://www.iesohealth.com/trustcentre/privacy-notices-nhs-patients",
        "_blank"
      )
  };

  const termsAndConditionsItem = {
    id: "terms-menu-item",
    label: "Terms and conditions",
    ariaLabel: "Terms and conditions",
    ariaDescription: "Link to terms and conditions page",
    onClick: () =>
      window.open(
        "https://www.iesohealth.com/trustcentre/terms-and-conditions-for-nhs-patients",
        "_blank"
      )
  };

  const logOutItem = {
    id: "log-out-menu-item",
    label: "Log out",
    ariaLabel: "Log out",
    ariaDescription: "Log out",
    onClick: () => {
      deleteCookie(COOKIE_KEYS.iesoCookieTimeout);
      logout();
    }
  };

  const items = [];
  items.push(admin ? myPatientItem : homeItem);
  items.push(myAccountItem);
  if (!admin) {
    items.push(privacyNoticesItem, termsAndConditionsItem);
  }
  items.push(logOutItem);

  return [{ items }];
};

interface IRenderBanner {
  hasClinicians: boolean;
  isOwner: boolean;
  isParticipant: boolean;
  isPatient: boolean;
  patientIsDischarged: boolean;
  isSessionActive: boolean;
  isClinician: boolean;
  isGlobalAdmin: boolean;
  sessionEndDate: string | null;
}

export function renderBanner({
  hasClinicians,
  isClinician,
  isGlobalAdmin,
  isOwner,
  isParticipant,
  isPatient,
  isSessionActive,
  patientIsDischarged,
  sessionEndDate
}: IRenderBanner) {
  const bannerConfig = [
    {
      key: "notAssignedClinitian",
      showBanner:
        !hasClinicians &&
        !isOwner &&
        isParticipant &&
        isPatient &&
        !patientIsDischarged,
      title: "You do not currently have a clinician assigned.",
      text: `Once a new clinician
        has joined, you’ll be able to send and receive messages again.
        Please call us on 0800 074 5560 if you have any concerns about your
        clinician allocation.`,
      appearance: "warning"
    },
    {
      key: "outOfSession",
      showBanner: !isSessionActive && !patientIsDischarged,
      title: `You are currently out of session.`,
      appearance: "warning"
    },
    {
      key: "notPatient",
      showBanner:
        ((isClinician && isParticipant) || isGlobalAdmin) && sessionEndDate,
      title: "Treatment has ended.",
      text: `You are no longer able to exchange messages with 
        this patient.`,
      appearance: "info"
    },
    {
      key: "dischargedPatient",
      showBanner: isPatient && patientIsDischarged,
      title: "You have been discharged from treatment.",
      text: `You can still access these 
        transcripts but cannot receive treatment or send messages to a clinician 
        here at this time. If you would like further treatment, you are welcome 
        to apply through our website.`,
      appearance: "warning"
    },
    {
      key: "liveSession",
      showBanner: isSessionActive,
      title: "Live session",
      appearance: "success"
    }
  ];
  const bannersToShow = bannerConfig.filter((config) => config.showBanner);

  return bannersToShow.map(
    ({ showBanner, appearance, title, text }) =>
      showBanner && <Banner appearance={appearance} title={title} text={text} />
  );
}

interface IGetClinicianSettings {
  messages?: IMessage[];
  otherParticipants?: IParticipant[];
  patient?: IParticipant;
}

type THeadLabels = { name: string; label: string; isOnline: boolean }[];

const getClinicianSettings = ({
  messages = [],
  otherParticipants = [],
  patient
}: IGetClinicianSettings): THeadLabels => {
  const msg = messages
    ?.reverse()
    ?.find(
      (message) =>
        message.identity_guid !== patient.identity_guid &&
        message.event_type === "Sentence"
    );

  const participantByGuid = otherParticipants.find(
    (participant) => participant.identity_guid === msg?.identity_guid
  );

  const clinicianLabel = "Clinician";
  const clinicianSettings =
    otherParticipants.length === 1
      ? {
          name: otherParticipants[0].fullname,
          label: clinicianLabel,
          isOnline: otherParticipants[0].is_online
        }
      : {
          name: participantByGuid?.fullname,
          label: clinicianLabel,
          isOnline: participantByGuid?.is_online
        };

  return [clinicianSettings];
};

interface IGetParticipants extends IGetClinicianSettings {
  isPatient: boolean;
  isGlobalAdmin: boolean;
  isClinician: boolean;
}

export const getParticipants = ({
  messages,
  otherParticipants,
  patient,
  isClinician,
  isGlobalAdmin,
  isPatient
}: IGetParticipants): THeadLabels => {
  if (isPatient) {
    return getClinicianSettings({ messages, otherParticipants, patient });
  }
  if (isGlobalAdmin) {
    return otherParticipants.map((participant) => ({
      name: participant.fullname,
      label: participant.is_owner ? "Clinician" : "Patient",
      isOnline: participant.is_online
    }));
  }
  if (isClinician) {
    return [
      {
        name: patient?.fullname,
        label: "Patient",
        isOnline: patient?.is_online
      }
    ];
  }
  return [];
};

export interface ISessionDates {
  time: string;
  start: string;
  end: string | null;
  label: string;
}

export const getSessionDates = (messages: IMessage[] = []): ISessionDates[] => {
  const timeCountMap: { [key: string]: number } = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages.sort((a: any, b: any) => {
    const aDate = a.recordingStartedUtc ?? a.time_utc;
    const bDate = b.recordingStartedUtc ?? b.time_utc;
    return aDate.localeCompare(bDate);
  });

  return messages.reduce((acc, message) => {
    if (message.event_type === "ScheduledSessionStarted") {
      const formattedTime = moment(message.time_utc).format("DD/MM/YY");
      const count = (timeCountMap[formattedTime] || 0) + 1;
      timeCountMap[formattedTime] = count;
      acc.push({
        time: message.time_utc,
        start: message.event_guid,
        end: null,
        label:
          count > 1
            ? `${formattedTime} Session (${count})`
            : `${formattedTime} Session`
      });
    } else if (
      message.event_type === "ScheduledSessionEnded" &&
      acc.length > 0
    ) {
      if (acc[acc.length - 1]) {
        acc[acc.length - 1].end = message.event_guid;
      }
    }
    return acc;
  }, []);
};

export const getSessionButtonLabel = (
  isStart: boolean,
  mobileView: boolean
): string => {
  const startLabel = mobileView ? "Start" : "Start session";
  const endLabel = mobileView ? "End" : "End session";
  return isStart ? startLabel : endLabel;
};
