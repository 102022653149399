const BYTES_PER_KIBIBYTE = 1024;
const SI_DATA_SIZE_UNITS = ["bytes", "KB", "MB", "GB", "TB"];

export function formatSizeInBytes(bytes: number, decimals = 2): string {
  if (decimals < 0 || decimals !== Math.round(decimals)) {
    throw new Error(
      `Invalid number of decimals: ${decimals}, must be 0 or a positive integer.`
    );
  }

  if (bytes === 0) {
    return "0 bytes";
  }

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(BYTES_PER_KIBIBYTE));
  const sizeRoundedToAppropriateUnit = parseFloat(
    (bytes / BYTES_PER_KIBIBYTE ** unitIndex).toFixed(decimals)
  );

  return `${sizeRoundedToAppropriateUnit} ${SI_DATA_SIZE_UNITS[unitIndex]}`;
}
