import React from "react";
import toast from "react-hot-toast";
import { Button } from "../Button/button.tsx";
import { StyledBanner, StyledBox } from "./styles.ts";

type TToastAppearance = "success" | "error" | "warning";

interface IToastParams {
  message: string;
  appearance: TToastAppearance;
  buttonText?: string;
  onClick?: () => void;
  duration?: number;
}

export const showToast = ({
  appearance,
  message,
  duration = 5000,
  buttonText,
  onClick
}: IToastParams) => {
  toast.custom(
    <StyledBanner
      dismissable
      appearance={appearance}
      noIcon={false}
      onDismiss={() => {
        toast.remove();
      }}
    >
      <StyledBox>
        {message}{" "}
        {onClick && buttonText && (
          <Button
            appearance="link"
            onClick={() => {
              onClick();
              toast.remove();
            }}
          >
            {buttonText}
          </Button>
        )}
      </StyledBox>
    </StyledBanner>,
    {
      duration
    }
  );
};

export default toast;
