import loadConfig from "config";

const getConfig = async () => loadConfig;

const attachmentsApiUrls = {
  add: (sessionId: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/attachment`
    ),
  delete: (sessionId: string, fileId: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/attachment/${fileId}`
    ),
  download: (sessionId: string, fileId: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/attachment/${fileId}`
    )
};

export default attachmentsApiUrls;
