/* eslint-disable no-console */
/* eslint-disable import/no-cycle */

import {
  getMessageHistoryInOrder,
  getConnectionStatus,
  hasConnectedBefore,
  getSessionId
} from "store/chatconnection/selectors";

import { getSessionTranscript } from "store/therapyservice/actions";
import { executeSignalRCallback } from "store/signalRHubInvoker";
import {
  ifSignalRReadyToConnect,
  setupSignalR,
  connectSignalR,
  joinSession,
  sendMessage,
  receiveEvent,
  finishSession,
  updateConnectionStatus,
  reconnectSignalR,
  dispatchConnectAction,
  connectionStateIsConnected
} from "./closures";

import { CONNECTION_STATUS_STRINGS } from "../consts";
import {
  SIGNAL_R_SETUP,
  SIGNAL_R_CONNECT,
  JOIN_APPOINTMENT,
  SIGNAL_R_SEND_MESSAGE,
  RECEIVE_NOTIFY_TYPING,
  USER_IS_TYPING,
  USER_IS_NOT_TYPING,
  SIGNAL_R_RECEIVE_EVENT,
  SIGNAL_R_CONNECTION_CLOSED,
  REMOVE_RECONNECT_BUTTON,
  SIGNAL_R_NOTIFY_TYPING,
  UPDATE_CONNECTION_STATUS,
  DISPLAY_RECONNECTED,
  USER_RECONNECT,
  FIRST_TIME_CONNECTED
} from "../types";
import { technicalDifficulties, userOffline } from "../actions";

export const HOUSTON_URL = "";

const DEFAULT_RETRIES = 3;
const DEFAULT_RETRY_WAIT = 2 ** DEFAULT_RETRIES * 10000;

const isTypingDisplayTimeoutMS = 12000;

const HIDE_RECONNECTED_AFTER_SECONDS = 3;

export const setNotTyping = (dispatch, middlewareState, identityGuid) => () => {
  // eslint-disable-next-line no-param-reassign
  middlewareState.typingTimeouts[identityGuid] = undefined;
  dispatch({
    type: USER_IS_NOT_TYPING,
    identityGuid
  });
};

export function createMiddleware() {
  const hideReconnectedMessage = (dispatch) => () =>
    dispatch({ type: DISPLAY_RECONNECTED, value: false });

  return ({ dispatch, getState, injectedState }) => {
    const middlewareState = injectedState || {
      connection: undefined,
      isTypings: {},
      typingTimeouts: {},
      reconnectTimeout: undefined,
      retriesIfConnectionClosed: undefined,
      retryDelayIfStartedSeconds: undefined,
      hideReconnectedMesageFn: hideReconnectedMessage(dispatch),
      dispatchConnectActionFn: dispatchConnectAction(dispatch)
    };

    const functions = {
      setupSignalR: setupSignalR(getState, dispatch, middlewareState),
      connectSignalR: connectSignalR(getState, dispatch, middlewareState),
      joinSession: joinSession(getState, dispatch, middlewareState),
      receiveEvent: receiveEvent(getState, dispatch, middlewareState),
      reconnectSignalR: reconnectSignalR(getState, dispatch, middlewareState),
      updateConnectionStatus: updateConnectionStatus(
        getState,
        dispatch,
        middlewareState
      )
    };
    return (next) => (action) => {
      const fun = {
        ...functions,
        ifSignalRReadyToConnect: ifSignalRReadyToConnect(
          getState,
          dispatch,
          middlewareState
        )(next, action),
        sendMessage: sendMessage(
          getState,
          dispatch,
          middlewareState
        )(next, action),
        finishSession: finishSession(
          getState,
          dispatch,
          middlewareState
        )(next, action),
        connectionStateIsConnected: connectionStateIsConnected(middlewareState)
      };

      executeSignalRCallback(action, middlewareState.connection);

      switch (action.type) {
        case UPDATE_CONNECTION_STATUS: {
          const connectionStatus = action.value;
          if (connectionStatus === CONNECTION_STATUS_STRINGS.Connected) {
            if (!hasConnectedBefore(getState()))
              dispatch({ type: FIRST_TIME_CONNECTED });
            else {
              dispatch({ type: DISPLAY_RECONNECTED, value: true });

              setTimeout(
                middlewareState.hideReconnectedMesageFn,
                HIDE_RECONNECTED_AFTER_SECONDS * 1000
              );
            }
          }
          break;
        }
        case REMOVE_RECONNECT_BUTTON: {
          const index = getMessageHistoryInOrder(getState())
            .map((xx) => xx.event_guid)
            .indexOf(action.event_guid);

          if (index >= 0) {
            const retValue = next(action);
            return retValue;
          }
          break;
        }
        case USER_RECONNECT:
          fun.reconnectSignalR(
            0,
            middlewareState.retryDelayIfStartedSeconds,
            middlewareState.dispatchConnectActionFn
          );
          break;
        case SIGNAL_R_CONNECTION_CLOSED:
          fun.reconnectSignalR(
            middlewareState.retriesIfConnectionClosed,
            middlewareState.retryDelayIfStartedSeconds,
            middlewareState.dispatchConnectActionFn
          );
          break;
        case SIGNAL_R_SETUP: {
          fun.updateConnectionStatus();
          const ret = fun.setupSignalR().then(() =>
            dispatch({
              type: SIGNAL_R_CONNECT,
              retries: DEFAULT_RETRIES,
              reconnect: false,
              skipGetSessionTranscript: action.skipGetSessionTranscript
            })
          );
          fun.updateConnectionStatus();
          return ret;
        }
        case SIGNAL_R_CONNECT: {
          const status = getConnectionStatus(getState());
          if (status !== CONNECTION_STATUS_STRINGS.Connected) {
            fun
              .connectSignalR()
              .then(() => {
                dispatch({ type: JOIN_APPOINTMENT });
                if (!action.skipGetSessionTranscript) {
                  dispatch(getSessionTranscript());
                }
                fun.updateConnectionStatus();
              })
              .catch((err) => {
                console.error(err.toString());
                if (action.retries >= 1) {
                  fun.updateConnectionStatus();

                  dispatch(
                    userOffline(
                      "Your computer seems to be offline; we'll try to reconnect shortly."
                    )
                  );

                  fun.updateConnectionStatus();
                  middlewareState.connectRetryTimeout = setTimeout(() => {
                    dispatch({
                      type: SIGNAL_R_CONNECT,
                      retries: action.retries - 1
                    });
                  }, Math.random() * 1000 + DEFAULT_RETRY_WAIT / 2 ** action.retries);
                } else {
                  fun.updateConnectionStatus();
                  dispatch(
                    userOffline(
                      "Your computer seems to be offline; we'll try to reconnect shortly."
                    )
                  );
                }
              });
          }
          fun.updateConnectionStatus();
          return next(action);
        }
        case JOIN_APPOINTMENT:
          fun.updateConnectionStatus();
          fun.joinSession().catch((err) => {
            console.error(err.toString());
            dispatch(
              technicalDifficulties(
                `Failed to join appointment: ${err.toString()}`
              )
            );
          });
          return next(action);
        case SIGNAL_R_SEND_MESSAGE:
          return fun.sendMessage();
        case SIGNAL_R_RECEIVE_EVENT:
          return fun.receiveEvent(next, action);
        case SIGNAL_R_NOTIFY_TYPING:
          if (fun.connectionStateIsConnected(middlewareState))
            middlewareState.connection.invoke(
              "NotifyTypingWithSessionGuid",
              getSessionId(getState())
            );
          break;
        case RECEIVE_NOTIFY_TYPING: {
          const identityGuid = action.model && action.model.identity_guid;
          if (!identityGuid) break;

          middlewareState.isTypings[identityGuid] = action.model;
          if (middlewareState.typingTimeouts[identityGuid] === undefined) {
            dispatch({
              type: USER_IS_TYPING,
              identityGuid
            });
          }

          clearTimeout(middlewareState.typingTimeouts[identityGuid]);

          middlewareState.typingTimeouts[identityGuid] = setTimeout(
            setNotTyping(dispatch, middlewareState, identityGuid),
            isTypingDisplayTimeoutMS
          );
          return Promise.resolve(`PROCESSED ${RECEIVE_NOTIFY_TYPING}`);
        }

        default:
        // Do Nothing
      }
      return next(action);
    };
  };
}
