import { SET_LOADING } from "./actions";

function loading(state = true, action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return action.data;
    default:
      return state;
  }
}

export default loading;
