import React from "react";
import { ThemeProvider } from "darker-matter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import Chat from "../Chat/index.ts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const AtlasChat = () => (
  <ThemeProvider
    extend={{ colors: { actionDestructive: { rest: "#FF4B4B" } } }}
  >
    <QueryClientProvider client={queryClient}>
      <Chat />
      <Toaster position="bottom-right" toastOptions={{ duration: Infinity }} />
    </QueryClientProvider>
  </ThemeProvider>
);

export default AtlasChat;
