import { useEffect, useRef } from "react";

const isOutOfIndexMovingUp = (
  event: KeyboardEvent,
  indexRef: React.MutableRefObject<number>
) => {
  return (
    event.key === "Tab" && event.shiftKey === true && indexRef.current === 0
  );
};

const isOutOfIndexMovingDown = (
  event: KeyboardEvent,
  lastIndex: number,
  indexRef: React.MutableRefObject<number>
) => {
  return (
    event.key === "Tab" &&
    event.shiftKey === false &&
    indexRef.current === lastIndex
  );
};

const isMovingUp = (event: KeyboardEvent) => {
  return (
    event.key === "ArrowUp" || (event.key === "Tab" && event.shiftKey === true)
  );
};

const isMovingDown = (event: KeyboardEvent) => {
  return (
    event.key === "ArrowDown" ||
    (event.key === "Tab" && event.shiftKey === false)
  );
};

interface IUseArrowNavigation {
  isShow: boolean;
  hide: () => void;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

// !!!IMPORTANT!!!
// You need to wrap ActionList in div and pass ref to this div
export const useArrowNavigation = ({
  isShow,
  hide,
  ref
}: IUseArrowNavigation) => {
  const indexRef = useRef<number>(-1);

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        const item = ref.current?.children[0].children[0];
        (item as HTMLElement)?.focus();
      }, 0);
      indexRef.current = 0;
    } else {
      indexRef.current = -1;
    }
  }, [isShow]);

  const handleKeyDown = (event: KeyboardEvent) => {
    const menuItems = ref.current?.children[0].children;
    const lastIndex = menuItems ? menuItems.length - 1 : 0;
    if (
      isOutOfIndexMovingUp(event, indexRef) ||
      isOutOfIndexMovingDown(event, lastIndex, indexRef) ||
      event.key === "Tab"
    ) {
      hide();
      return;
    }

    if (isMovingUp(event)) {
      const currentIndex = indexRef.current - 1;
      const newIndex = currentIndex < 0 ? lastIndex : currentIndex;
      indexRef.current = newIndex;

      if (event.key === "ArrowUp") {
        (menuItems?.[newIndex] as HTMLElement)?.focus();
      }
    }

    if (isMovingDown(event)) {
      const currentIndex = indexRef.current + 1;
      const newIndex = currentIndex > lastIndex ? 0 : currentIndex;
      indexRef.current = newIndex;

      if (event.key === "ArrowDown") {
        (menuItems?.[newIndex] as HTMLElement)?.focus();
      }
    }
  };

  return { handleKeyDown };
};
