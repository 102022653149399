/* eslint-disable */
/* Stores testable functions of the signature  (getState, dispatch, state) => (*) => {} */
import * as signalR from "@aspnet/signalr";
import sanitizeMessage, { urlRegex } from "utils/sanitize-message";
import { getMyId, getAccessToken } from "Identity/selectors";
import {
  amITheSessionOwner,
  getSessionId,
  getSessionStatus,
  isUnloading,
  getTherapyRoomId
} from "store/chatconnection/selectors";
import { TELEMETRY_KEYS } from "config/telemetry.ts";
import registerHandlers from "./registerHandlers";
import loadConfig from "../../../config/index";
import {
  CHAT_SANITIZED_INCOMING_MESSAGE,
  FINISH_SESSION_PATIENT,
  FINISH_SESSION_THERAPIST,
  UPDATE_CONNECTION_STATUS,
  USER_IS_NOT_TYPING,
  SIGNAL_R_MESSAGE_FAILED_CHECK,
  SIGNAL_R_CHECK_SENT_MESSAGE,
  SIGNAL_R_CONNECT,
  SESSION_CLOSED,
  UPDATE_IS_SESSION_ACTIVE
} from "../types";

import { CONNECTION_STATUS_STRINGS } from "../consts";

import { NAME } from "..";
import { technicalDifficulties } from "../actions";

const uuidv4 = require("uuid/v4");

const DEFAULT_RETRIES = 3;
const DEFAULT_RETRY_DELAY_IF_STARTED_SECONDS = 1;

/** *
 ** ifNotConnected - If we're connected or connecting then don't run the callback
 */
export const ifSignalRReadyToConnect = (
  getState,
  dispatch,
  middlewareState
) => (next, action) => (callback) => {
  if (
    connectionStateIsConnectingOrConnected(middlewareState) || // connection is either connecting or connected
    middlewareState.info === undefined
  ) {
    dispatch({
      type: "log",
      value: "SIGNAL_R_CONNECT: Not right now, state is incorrect."
    });
    return next(action);
  }
  return callback();
};

const connectionStateIsConnectingOrConnected = (middlewareState) =>
  middlewareState.connection &&
  middlewareState.connection.connection &&
  middlewareState.connection.connection.connectionState <=
    connectionStatusStringLookup[CONNECTION_STATUS_STRINGS.Connected];

export const connectionStateIsConnected = (middlewareState) => () =>
  middlewareState.connection &&
  middlewareState.connection.connection &&
  middlewareState.connection.connection.connectionState &&
  middlewareState.connection.connection.connectionState ===
    connectionStatusStringLookup.indexOf(CONNECTION_STATUS_STRINGS.Connected);

let config;
const configRequest = loadConfig.then((c) => {
  config = c;
});

export const setupSignalR = (
  getState,
  dispatch,
  middlewareState
) => async () => {
  await configRequest;
  middlewareState.connection = new signalR.HubConnectionBuilder()
    .withUrl(`${config.tss_url}/chatHub`, {
      accessTokenFactory: () => getAccessToken(getState())
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  registerHandlers(getState, dispatch, middlewareState);
  return Promise.resolve();
};

export const connectSignalR = (getState, dispatch, middlewareState) => () => {
  return middlewareState.connection.start();
};

export const dispatchConnectAction = (dispatch) => (retries) => () => {
  dispatch({
    type: SIGNAL_R_CONNECT,
    retries,
    reconnect: true
  });
};

export const reconnectSignalR = (getState, dispatch, middlewareState) => (
  retries = DEFAULT_RETRIES,
  retryDelayIfStartedSeconds = DEFAULT_RETRY_DELAY_IF_STARTED_SECONDS,
  dispatchConnectActionFn = dispatchConnectAction(dispatch)
) => {
  if (isUnloading(getState())) {
    return;
  }

  updateConnectionStatus(getState, dispatch, middlewareState)();
  const status = getSessionStatus(getState());

  if (middlewareState.reconnectTimeout) {
    clearTimeout(middlewareState.reconnectTimeout);
  }

  if (status !== "finished") {
    middlewareState.reconnectTimeout = setTimeout(
      dispatchConnectActionFn(retries),
      retryDelayIfStartedSeconds * 1000
    );
  }
  updateConnectionStatus(getState, dispatch, middlewareState)();
};

export const isConnected = (connectionState) => {
  return (
    connectionState ===
    connectionStatusStringLookup.indexOf(CONNECTION_STATUS_STRINGS.Connected)
  );
};

const connectionStatusStringLookup = [
  CONNECTION_STATUS_STRINGS.Connecting,
  CONNECTION_STATUS_STRINGS.Connected,
  CONNECTION_STATUS_STRINGS.Disconnected
];

export const updateConnectionStatus = (
  getState,
  dispatch,
  middlewareState
) => () => {
  const status =
    middlewareState &&
    middlewareState.connection &&
    middlewareState.connection.connection &&
    middlewareState.connection.connection.connectionState;
  if (
    status !== undefined &&
    getState()[NAME].connection_status !== connectionStatusStringLookup[status]
  ) {
    dispatch({
      type: UPDATE_CONNECTION_STATUS,
      value: connectionStatusStringLookup[status],
      enum_status: status
    });
  }
};

export const joinSession = (getState, dispatch, middlewareState) => () => {
  try {
    return middlewareState.connection
      .invoke("Join", getSessionId(getState()))
      .then(() => {
        return dispatch({
          type: "log",
          value: `RUN_JOIN_SESSION: Joined Session: ${getSessionId(getState())}`
        });
      });
  } catch (ex) {
    dispatch(
      technicalDifficulties(
        `join session failed, ${ex} on session_id ${getSessionId(getState())}`
      )
    );
  }
};

const replacer = (match) => {
  if (blankNextToDot(match)) return match;
  if (oneCharacterAfterLastDot(match)) return match;

  const urlStartsWith = match.substring(0, 4).toLowerCase();
  const href = urlStartsWith === "http" ? match : `https://${match}`;

  return `<a href='${href}' target='_blank' rel='noreferrer noopener'>${match}</a>`;
};

function blankNextToDot(match) {
  var betweenDotsArr = match.split(".");
  return !betweenDotsArr.length || betweenDotsArr.includes("");
}

function oneCharacterAfterLastDot(match) {
  var noColonDoubleSlash = match.replace("://", "");
  var firstSlashIdx = noColonDoubleSlash.indexOf("/");
  var noprotocol =
    firstSlashIdx === -1
      ? match
      : noColonDoubleSlash.substring(0, firstSlashIdx);

  var lastDotPos = noprotocol.lastIndexOf(".");
  return noprotocol.length - lastDotPos === 2;
}

export const sendMessage = (getState, dispatch, middlewareState) => (
  next,
  action
) => () => {
  const return_value = next(action);
  const state = getState();
  const { telemetry, identity } = state;
  const therapyRoomId = getTherapyRoomId(state);
  const userId =
    identity.access_token_decoded["https://thinkwell.co.uk/ieso_user_id"];
  try {
    middlewareState.connection
      .invoke("DistributeEvent", {
        ...action.model,
        event_type: action.eventType || "Sentence",
        content: action.content
          ? sanitizeMessage(action.content, {
              options: {
                textFilter(text) {
                  return text.replace(urlRegex, replacer);
                }
              }
            })
          : action.content,
        attachments: action.attachments
      })
      .then((...result) => {
        if (action.eventType === "Sentence") {
          telemetry.trackEvent(TELEMETRY_KEYS.MESSAGE_SENT, {
            userId,
            therapyRoomId
          });
        }
        if (action.eventType === "ScheduledSessionStarted") {
          telemetry.trackEvent(TELEMETRY_KEYS.SESSION_STARTED, {
            userId,
            therapyRoomId
          });
        }
        if (action.eventType === "ScheduledSessionEnded") {
          telemetry.trackEvent(TELEMETRY_KEYS.SESSION_ENDED, {
            userId,
            therapyRoomId
          });
        }
        dispatch({
          type: "log",
          value: `DistributeEvent: Sent message: ${result}`
        });
      })
      .catch((error) => {
        console.error("On sendMessage==>>", error, action, getState());
        if (
          action.eventType === "ScheduledSessionStarted" ||
          action.eventType === "ScheduledSessionEnded"
        ) {
          dispatch({
            type: UPDATE_IS_SESSION_ACTIVE,
            value: action.eventType !== "ScheduledSessionStarted"
          });
        }
        dispatch({
          type: SIGNAL_R_MESSAGE_FAILED_CHECK,
          ...action.model
        });
      });
    setTimeout(() => {
      dispatch({
        type: SIGNAL_R_CHECK_SENT_MESSAGE,
        ...action.model
      });
    }, 500);
    setTimeout(() => {
      dispatch({
        type: SIGNAL_R_MESSAGE_FAILED_CHECK,
        ...action.model
      });
    }, 30000);
  } catch (ex) {
    console.error(`error ${ex}`);
    dispatch(technicalDifficulties("sendMessage failed"));
  } finally {
    return return_value;
  }
};

export const receiveEvent = (getState, dispatch, middlewareState) => (
  next,
  action
) => {
  const state = getState();
  const { telemetry, identity } = state;
  const therapyRoomId = getTherapyRoomId(state);

  const userId =
    identity.access_token_decoded["https://thinkwell.co.uk/ieso_user_id"];
  switch (action.model && action.model.event_type) {
    case 0:
    case "Sentence":
    case "ScheduledSessionStarted":
    case "ScheduledSessionEnded":
      if (action.model) {
        const sanitizedContent = sanitizeMessage(action.model.content);
        const newModel = {
          ...action.model,
          sanitizedContent
        };

        clearPreviousTypingTimeout(middlewareState, newModel.identity_guid);
        dispatch({
          type: USER_IS_NOT_TYPING,
          identityGuid: newModel.identity_guid
        });

        const new_action = {
          ...action,
          model: newModel,
          type: CHAT_SANITIZED_INCOMING_MESSAGE
        };
        dispatch(new_action);

        if (action.model.event_type === "Sentence") {
          if (action.model.attachments.length) {
            telemetry.trackEvent(TELEMETRY_KEYS.ATTACHMENT_RECEIVED, {
              userId,
              therapyRoomId
            });
          } else {
            telemetry.trackEvent(TELEMETRY_KEYS.MESSAGE_RECEIVED, {
              userId,
              therapyRoomId
            });
          }
        }

        if (action.model.event_type !== "Sentence") {
          dispatch({
            type: UPDATE_IS_SESSION_ACTIVE,
            value: action.model.event_type === "ScheduledSessionStarted"
          });
        }
      }
      break;
    case 4:
    case "Finish":
      if (amITheSessionOwner(getState())) {
        dispatch({ type: FINISH_SESSION_THERAPIST });
      } else {
        dispatch({ type: FINISH_SESSION_PATIENT });
      }
      break;
    case 5:
    case "Close":
      dispatch({ type: SESSION_CLOSED });
      break;
    default:
  }
  return next(action);
};

export const finishSession = (getState, dispatch, middlewareState) => (
  next,
  action
) => () => {
  try {
    middlewareState.connection
      .invoke("DistributeEvent", {
        content: JSON.stringify({ status: action.status }),
        event_guid: uuidv4(),
        event_type: "Finish",
        identity_guid: getMyId(getState()),
        time_utc: new Date().toUTCString()
      })
      .then((...result) => {
        dispatch({
          type: "log",
          value: `DistributeEvent: Finished session: ${result}`
        });
      })
      .catch((error) => {
        console.error("On finishSession", error, action, getState());
        dispatch(technicalDifficulties("finishSession failed"));
      });
  } catch (ex) {
    console.error(`error ${ex}`);
  } finally {
    return next(action);
  }
};

function clearPreviousTypingTimeout(middlewareState, idGuid) {
  if (middlewareState.typingTimeouts[idGuid] !== undefined) {
    clearTimeout(middlewareState.typingTimeouts[idGuid]);
    middlewareState.typingTimeouts[idGuid] = undefined;
  }
}
