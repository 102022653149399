import { createSelector } from "reselect";

export const getUser = (state) => state.identity && state.identity.user;

const getMyIdentityId = (state) =>
  state.identity &&
  state.identity.id_token_decoded["https://thinkwell.co.uk/ieso_user_id"];

const accessToken = (user) => user && user.access_token;

const GivenName = createSelector([getUser], (user) => user && user.name);

export const getUserName = createSelector(
  [getUser],
  (user) => user && user.name
);

export const getAccessToken = createSelector(
  [getUser],
  (user) => accessToken(user) || null
);

export const getBearerAuthHeader = createSelector(
  [getUser],
  (user) => (accessToken(user) && `Bearer ${accessToken(user)}`) || null
);

export const getToken = createSelector([getUser], (user) => accessToken(user));

export const getMyId = createSelector([getMyIdentityId], (id) => {
  if (!id) {
    return null;
  }
  return id;
});

export const getMyGivenName = createSelector([GivenName], (myname) => {
  if (!myname) {
    return "";
  }
  return myname;
});

export const getMyName = (state) =>
  state.identity &&
  `${state.identity.id_token_decoded.given_name} ${state.identity.id_token_decoded.family_name}`;

export const getMyRole = (state) =>
  (state.identity &&
    state.identity.id_token_decoded[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ]) || [state.identity.id_token_decoded["https://thinkwell.co.uk/roles"]];

export const getTelemetry = (state) => state.telemetry;
