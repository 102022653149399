export const COOKIE_KEYS = {
  iesoCookieTimeout: "iesoCookieTimeout"
};

const cookieDomain = "iesohealth.uk";
const tssDevDomain = "thinkwell.co.uk";

function checkLocalEnv() {
  return window.location.hostname === "localhost";
}
function checkDevEnv() {
  const hostname = window.location.hostname;
  return hostname.includes("int") || hostname.includes("qa");
}

export function createCookie<T = string>(name: string, value: T) {
  const localEnv = checkLocalEnv();
  const devEnv = checkDevEnv();
  const cookieValue = `${name}=${value}`;
  const domainValue = localEnv ? "localhost" : cookieDomain;
  const domain = `domain=${domainValue}`;
  const options = "path=/";

  document.cookie = `${cookieValue}; ${domain}; ${options}`;
  if (devEnv || localEnv) {
    document.cookie = `${cookieValue}; domain=${tssDevDomain}; ${options}`;
  }
}

export function readCookie(name: string, source?: string) {
  const cookieSource = source || document.cookie;
  const cookies = cookieSource.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(`${name}=`) === 0) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

export function deleteCookie(name: string) {
  const localEnv = checkLocalEnv();
  const devEnv = checkDevEnv();
  const domainValue = localEnv ? "localhost" : cookieDomain;
  const domain = `domain=${domainValue}`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${domain}`;

  if (devEnv || localEnv) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${tssDevDomain}`;
  }
}

type TListenCallback = (arg: { oldValue: string; newValue: string }) => void;

export function listenCookieChange(callback: TListenCallback) {
  let lastCookie = document.cookie;
  return setInterval(() => {
    const cookie = document.cookie;

    if (cookie !== lastCookie) {
      try {
        callback({ oldValue: lastCookie, newValue: cookie });
      } finally {
        lastCookie = cookie;
      }
    }
  }, 1000);
}
