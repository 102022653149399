import React, { FC, useState } from "react";
import { Text } from "darker-matter";
import { IBadgeProps } from "darker-matter/build/components/Badge/Badge";
import { StyledBadge, StyledCollapsible } from "./styles.ts";

interface IBannerProps {
  title: string;
  text: string;
  appearance: string;
}

const Banner: FC<IBannerProps> = ({ title, text, appearance }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onClick = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <StyledBadge appearance={appearance as IBadgeProps["appearance"]}>
      <StyledCollapsible
        ariaLabel={text ? "Click to expand" : title}
        ariaDescription={text}
        header={<Text>{title}</Text>}
        iconPosition={text ? "far" : "hidden"}
        iconSet="up-down"
        disabled={!text}
        onToggle={onClick}
        open={collapsed}
      >
        {text && <Text>{text}</Text>}
      </StyledCollapsible>
    </StyledBadge>
  );
};

export default Banner;
