/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITimelineEvent } from "types/chatTypes.ts";
import { useEffect, useState } from "react";
import { useGetCompositionsApi } from "../../api/timeline/useCompositionsApi.ts";
import { useGetEventsApi } from "../../api/timeline/useEventsApi.ts";

interface ITimeline {
  data: ITimelineEvent[];
  isLoading: boolean;
  error: unknown;
}

export const useTimeline = (sessionId: string): ITimeline => {
  const [timeline, setTimeline] = useState<ITimeline>({
    data: null,
    isLoading: true,
    error: null
  });
  const { data: eventsData, isLoading: isLoadingEvents } = useGetEventsApi(
    sessionId
  );
  const {
    data: compositionsData,
    isLoading: isLoadingCompositions
  } = useGetCompositionsApi(sessionId);

  useEffect(() => {
    let dataToSet;
    if (eventsData && compositionsData) {
      dataToSet = [...eventsData, ...compositionsData];
    } else if (eventsData && !isLoadingCompositions) {
      dataToSet = eventsData;
    } else if (compositionsData && !isLoadingEvents) {
      dataToSet = compositionsData;
    }

    if (dataToSet) {
      dataToSet.sort((a, b) => {
        const aDate = a.recordingStartedUtc ?? a.time_utc;
        const bDate = b.recordingStartedUtc ?? b.time_utc;
        return aDate.localeCompare(bDate);
      });

      setTimeline((t) => ({ ...t, data: dataToSet, isLoading: false }));
    }
  }, [eventsData, compositionsData, isLoadingEvents, isLoadingCompositions]);

  return timeline;
};
