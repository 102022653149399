/* eslint-disable */
import { combineReducers } from "redux";

import { SIGNAL_R_SETUP } from "store/chatconnection/types";

import { types as t } from "./types";

function appointment_id(state = "", action = {}) {
  switch (action.type) {
    case t.CHAT_SET_APPOINTMENT_ID:
      if (action.value) {
        return action.value;
      }
      break;
    default:
  }
  return state;
}

function appointment_data(state = {}, action = {}) {
  switch (action.type) {
    case t.CHAT_SET_APPOINTMENT_INFO:
      if (action.data) {
        return action.data;
      }
      break;
    default:
  }
  return state;
}

function case_appointments(state = {}, action = {}) {
  switch (action.type) {
    case t.SET_CASE_APPOINTMENTS:
      if (action.data) {
        return action.data;
      }
      break;
    default:
  }
  return state;
}

function participants(state = {}, action = {}) {
  switch (action.type) {
    case t.CHAT_SET_APPOINTMENT_INFO:
      if (action.data && action.data.participants) {
        const new_state = {};
        action.data.participants.forEach(xx => {
          new_state[xx.identity_guid] = xx;
        });
        return new_state;
      }
      break;
    default:
  }
  return state;
}

function transport_state(state = "initial", action = {}) {
  switch (action.type) {
    case SIGNAL_R_SETUP:
      return "setup";
    default:
  }
  return state;
}

// PROVIDER INFO
function providerInfo(state = {}, action = {}) {
  switch (action.type) {
    case t.CHAT_SET_PROVIDER_INFO:
      const new_state = {};
      if (action.data) {
        Object.keys(action.data).forEach(
          xx => (new_state[xx] = action.data[xx])
        );
        return new_state;
      }
      break;
    default:
  }
  return state;
}

function is_typing(state = {}, action = {}) {
  switch (action.type) {
    case t.USER_IS_TYPING:
      if (action.identity) {
        return Object.assign({}, state, { [action.identity]: true });
      }
      break;
    case t.USER_IS_NOT_TYPING:
      if (action.identity) {
        return Object.assign({}, state, { [action.identity]: false });
      }
      break;
    default:
  }
  return state;
}

const rootReducer = combineReducers({
  appointment_data,
  case_appointments,
  appointment_id,
  participants,
  transport_state,
  providerInfo,
  is_typing
});

export default rootReducer;
