// chat/actionTypes.js
// types contains the name of the actions that you are dispatching in your application. Good practice is to scope the names based on the feature they belong to. This helps when debugging complex applications.

// TODO: @itti seperate out appointment from chat feature

export const types = {
  GET_APPOINTMENT_INFO: "chatendpoints/GET_APPOINTMENT_INFO",
  CHAT_SET_APPOINTMENT_ID: "chatendpoints/SET_APPOINTMENT_ID",
  CHAT_SET_APPOINTMENT_INFO: "chatendpoints/SET_APPOINTMENT_INFO",
  CHAT_GET_TRANSCRIPT: "chatendpoints/GET_TRANSCRIPT",
  CHAT_SET_JOIN_RESPONSE: "chatendpoints/SET_JOIN_RESPONSE",
  CHAT_UPDATE_APPOINTMENT_STATUS: "chatendpoints/UPDATE_APPOINTMENT_STATUS",
  CHAT_SET_PROVIDER_INFO: "chatendpoints/SET_PROVIDER_INFO",
  CHAT_SET_CASE_QUESTIONNAIRES: "chatendpoints/SET_CASE_QUESTIONNAIRES",
  CHAT_SANITIZED_INCOMING_MESSAGE: "chatendpoints/SANITIZED_INCOMMING_MESSAGE",
  SET_CASE_APPOINTMENTS: "chatendpoints/SET_CASE_APPOINTMENTS"
};
