import { css } from "@styled-system/css";

type TBasicColor =
  | "primary"
  | "extended1"
  | "extended2"
  | "extended3"
  | "neutral"
  | "info"
  | "warning"
  | "error"
  | "success"
  | "ink";

const darkOnHover = ["ink"];
const lightOnFocus = ["error"];

export const buttonMixin = (color: TBasicColor) =>
  css({
    backgroundColor: `${color}.main`,
    "&:hover": {
      backgroundColor: darkOnHover.includes(color)
        ? `${color}.dark`
        : `${color}.light`
    },
    "&:active": {
      backgroundColor: `${color}.dark`
    },
    "&:focus": {
      backgroundColor: lightOnFocus.includes(color)
        ? `${color}.light`
        : `${color}.main`,
      boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${
        color === "error" ? "#C83232" : "#017A67"
      }`
    },
    transition: "background-color 0.2s"
  });

export const linkButtonMixin = css({
  padding: "2px",
  borderRadius: "4px",
  backgroundColor: `inherit`,
  "&:hover": {
    backgroundColor: `neutral.light`
  },
  "&:active": {
    backgroundColor: `neutral.dark`
  },
  "&:focus": {
    boxShadow: `0 0 0 2px #336B66`
  },
  transition: "background-color 0.2s"
});
