import loadConfig from "config";

const getConfig = async () => loadConfig;

const deletedEventApiUrls = {
  addDeletedEvent: (eventId: number) =>
    getConfig().then(
      (config) => `${config.tss_url}/therapy/v1/deletedEvents/${eventId}`
    ),
  removeDeletedEvent: (eventId: number) =>
    getConfig().then(
      (config) => `${config.tss_url}/therapy/v1/deletedEvents/${eventId}`
    )
};

export default deletedEventApiUrls;
