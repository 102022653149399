import { connect } from "react-redux";
import {
  amITheSessionOwner,
  getHasClinicians,
  getConnectionStatus,
  getSessionId,
  getSessionStatus,
  getReadonlyMode
} from "store/chatconnection/selectors";
import throttleNotify from "utils/throttleNotify";
import {
  notifyTyping as notifyTypingAction,
  sendMessage as sendMessageAction
} from "store/chatconnection/actions";
import { getMyId } from "Identity/selectors";
import ChatTextBox from "./ChatTextbox.tsx";

function mapStateToProps(state) {
  const connectionStatus = getConnectionStatus(state);
  const sessionId = getSessionId(state);
  const sessionStatus = getSessionStatus(state);
  const myId = getMyId(state);
  const isParticipant = !!state.chatconnection.participants[myId];
  const hasClinicians = getHasClinicians(state);
  const isOwner = amITheSessionOwner(state);
  const readonlyMode = getReadonlyMode(state);

  return {
    connectionStatus,
    sessionId,
    sessionStatus,
    isParticipant,
    hasClinicians,
    isOwner,
    readonlyMode
  };
}

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (messageText, attachments) => {
    const htmlValue = messageText.trim().replaceAll("\n", "<br />");
    dispatch(sendMessageAction(htmlValue, attachments));
  },
  notifyTyping: throttleNotify(() => {
    dispatch(notifyTypingAction());
  }, 5000)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatTextBox);
