import styled from "styled-components";
import { variant } from "styled-system";
import { css } from "@styled-system/css";
import { Box, Utterance, Text } from "darker-matter";

export const StyledTimelineBox = styled(Box)(
  css({
    maxWidth: "700px",
    mx: [4, 4, "auto"],
    marginBottom: 4
  })
);

export const StyledTimestamp = styled.div<{ $firstElement: boolean }>(
  ({ $firstElement }) =>
    css({
      display: "flex",
      justifyContent: "center",
      paddingTop: $firstElement ? 0 : 6,
      paddingBottom: 6
    })
);

export const StyledBubble = styled(Utterance.Bubble)(
  variant({
    prop: "orientation",
    variants: {
      left: css({
        alignSelf: "flex-start",
        mr: 10,
        wordBreak: "break-word"
      }),
      right: css({
        alignSelf: "flex-end",
        ml: 10,
        wordBreak: "break-word"
      })
    }
  })
);

export const StyledText = styled(Text)`
  max-width: 80ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
