import React, { FC } from "react";
import { CONNECTION_STATUS_STRINGS } from "store/chatconnection/consts";
import { StyledTextboxContainer } from "./styles.ts";
import BottomPanel from "./components/BottomPanel.tsx";

interface IChatTextBoxProps {
  connectionStatus: string; // CONNECTION_STATUS_STRINGS
  sessionId: string;
  sessionStatus: string;
  isParticipant: boolean;
  hasClinicians: boolean;
  isOwner: boolean;
  readonlyMode: boolean;
  sendMessage: (message: string) => void;
  notifyTyping: () => void;
}

const ChatTextBox: FC<IChatTextBoxProps> = ({
  connectionStatus,
  sessionId,
  sessionStatus,
  isParticipant,
  hasClinicians,
  isOwner,
  readonlyMode,
  sendMessage,
  notifyTyping
}) => {
  const handleTyping = () => {
    Promise.resolve().then(() => notifyTyping());
  };

  if (
    readonlyMode ||
    !isParticipant ||
    sessionStatus === "finished" ||
    (!hasClinicians && !isOwner)
  ) {
    return null;
  }

  return (
    <StyledTextboxContainer>
      <BottomPanel
        sessionId={sessionId}
        isSendButtonDisabled={
          connectionStatus !== CONNECTION_STATUS_STRINGS.Connected
        }
        onSend={sendMessage}
        onChange={handleTyping}
      />
    </StyledTextboxContainer>
  );
};

export default ChatTextBox;
