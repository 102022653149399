import styled from "styled-components";
import { css } from "@styled-system/css";

export const StyledTimelineBox = styled.div(
  css({
    bg: "neutral.main",
    overflowY: "auto",
    flexGrow: 1
  })
);
