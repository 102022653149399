import React, { FC, ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IdleDetector } from "./components/IdleDetector/IdleDetector.tsx";

interface IAuthGuardProvider {
  children: ReactNode;
  sessionTimeout: number;
}

export const AuthGuard: FC<IAuthGuardProvider> = ({
  children,
  sessionTimeout
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  }

  return (
    <>
      {children}
      <IdleDetector sessionTimeout={sessionTimeout} />
    </>
  );
};

export default AuthGuard;
