/* eslint-disable camelcase */
import { combineReducers } from "redux";
import { decode } from "jsonwebtoken";

import { USER_FOUND } from "./actions";

// Reducers
function access_token_decoded(state = {}, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return decode(action.payload.access_token);
    default:
      return state;
  }
}

function id_token_decoded(state = {}, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return decode(action.payload.id_token);
    default:
      return state;
  }
}

function userFoundCount(state = 0, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return state + 1;
    default:
      return state;
  }
}

function reduxUser(state = {}, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  access_token_decoded,
  id_token_decoded,
  userFoundCount,
  user: reduxUser
});
