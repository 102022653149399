import styled from "styled-components";
import css from "@styled-system/css";
import { Box, Icon } from "darker-matter";

interface IHeaderProps {
  appearance: "normal" | "link";
  disabled: boolean;
  expanded: boolean;
}

const headerConfig = {
  shouldForwardProp: (name: string) =>
    !["appearance", "disabled", "expanded"].includes(name)
};

export const Header = styled(Box).withConfig(headerConfig)<IHeaderProps>(
  (props) =>
    css({
      display: "flex",
      alignItems: "center",
      columnGap: 3,
      width: "100%",
      padding: 0,
      border: "none",
      backgroundColor: "inherit",
      cursor: props.disabled ? "default" : "pointer",
      pointerEvents: props.disabled ? "none" : undefined,
      // eslint-disable-next-line no-nested-ternary
      color: props.disabled
        ? "ink.lighter"
        : props.appearance === "link"
        ? "link.main"
        : "ink.main",
      fontWeight: "bold",
      "&:focus": {
        outline: "none",
        boxShadow: props.disabled
          ? "none"
          : `0 0 0 2px #017A67
        `
      }
    })
);

interface IHeaderIconProps {
  iconPosition?: "hidden" | "near" | "far";
}

const headerIconConfig = {
  shouldForwardProp: (name: string) => !["iconPosition"].includes(name)
};

export const HeaderIcon = styled(Icon)
  .attrs({ decorative: true })
  .withConfig(headerIconConfig)<IHeaderIconProps>(({ iconPosition }) =>
  css({
    width: "18px",
    height: "18px",
    order: iconPosition === "far" ? 2 : 0,
    display: iconPosition === "hidden" ? "none" : undefined
  })
);

export const HeaderContent = styled("span")({
  order: 1,
  flexGrow: 1
});

export const DetailsContainer = styled(Box)(
  css({
    mt: 3
  })
);
