// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964
import React from "react";

import {
  IButtonComponentProps,
  IBaseAction,
  IDisableableAction,
  IIconOrPositionedIcon,
  IPositionedIcon,
  Spinner,
  Icon,
  ValidIcon,
  Box
} from "darker-matter";
import { StyledButton } from "./styles.ts";

export interface IButtonProps
  extends IButtonComponentProps,
    IBaseAction,
    IDisableableAction,
    IIconOrPositionedIcon {
  /**
   * Choose from one of the main button types.
   */
  appearance?:
    | "primary"
    | "destructive"
    | "secondary"
    | "tertiary"
    | "tertiary-muted"
    | "link"
    | "link-muted";
  /**
   * Content to display inside the button
   */
  children?: React.ReactNode;
  /**
   * ID of the element the button controls
   */
  ariaControls?: string;
  /**
   * Tells the screen-reader there's a open connected item
   */
  ariaExpanded?: boolean;
  /**
   * Tells the screen-reader the button is pressed
   */
  ariaPressed?: boolean;
  /**
   * Reference to component root content
   */
  forwardedRef?: React.Ref<HTMLButtonElement>;
  /**
   * Expand the width of the button to fill the parent container
   */
  fullWidth?: boolean;
  /**
   *  Button is in a loading state, shows a spinner icon, hides text and disables button
   */
  loading?: boolean;
  /**
   * Callback when button becomes focussed
   */
  onFocus?: (e: React.SyntheticEvent) => void;
  /**
   * Used by `<ButtonGroup/>` to indicate sequence position and control border-radius
   * in a segmented collection
   */
  segment?: "first" | "intermediate" | "last" | "none";
  /**
   * Change the size of the button
   */
  magnitude?: "small" | "medium" | "large";
  /**
   * Indicates whether this button is of type "submit"
   */
  submit?: boolean;
  ariaDescription?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  ariaDisabled?: boolean;
}

/**
 *
 * Buttons are used to highlight actions to the user. They can be given visual importance using the appearance property and can be used to navigate or perform tasks.
 */
export const Button: React.FC<IButtonProps> = ({
  ariaLabel,
  ariaDescription,
  appearance = "secondary",
  segment = "none",
  children,
  ariaControls,
  ariaExpanded,
  disabled = false,
  forwardedRef,
  icon,
  id,
  label,
  loading = false,
  onClick,
  onFocus,
  magnitude = "medium",
  submit = false,
  ...props
}) => {
  // set disabled state based on loading state
  if (loading) {
    disabled = true;
  }

  // set icon size based on button size
  let iconSize = "24px";
  switch (magnitude) {
    case "small":
      iconSize = "18px";
      break;
    case "large":
      iconSize = "28px";
      break;
    default:
      break;
  }

  const iconWithPosition = icon as IPositionedIcon;
  const iconOnly = icon as ValidIcon;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getTextOpacity = (loading: boolean, disabled: boolean): number => {
    if (loading) {
      return 0;
      // eslint-disable-next-line no-else-return
    } else {
      return disabled ? 0.4 : 1;
    }
  };

  return (
    <StyledButton
      appearance={appearance}
      magnitude={magnitude}
      segment={segment}
      id={id}
      type={submit ? "submit" : "button"}
      onClick={onClick}
      onFocus={onFocus}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      {...(typeof ariaExpanded === "boolean"
        ? { "aria-expanded": ariaExpanded }
        : {})}
      // eslint-disable-next-line jsx-a11y/aria-props
      aria-description={ariaDescription}
      disabled={disabled}
      ref={forwardedRef}
      flexDirection={
        iconWithPosition && iconWithPosition.position === "after"
          ? "row-reverse"
          : "row"
      }
      {...props}
      {...(appearance.includes("link") && {
        px: 0,
        py: 0,
        segment: "intermediate",
        minHeight: "auto"
      })}
    >
      {loading && (
        <Box position="absolute">
          <Spinner />
        </Box>
      )}
      {(iconOnly || iconWithPosition) && (
        <Icon
          name={iconWithPosition.name || iconOnly}
          decorative
          opacity={getTextOpacity(loading, disabled)}
          height={
            (iconWithPosition.size && iconWithPosition.size[1]) || iconSize
          }
          width={
            (iconWithPosition.size && iconWithPosition.size[0]) || iconSize
          }
        />
      )}
      {(label || children) && (
        <Box
          mr={
            iconWithPosition && iconWithPosition.position === "after" ? 2 : null
          }
          ml={
            typeof iconOnly === "string" ||
            (iconWithPosition && iconWithPosition.position) === "before"
              ? 2
              : null
          }
          opacity={getTextOpacity(loading, disabled)}
        >
          {label || children}
        </Box>
      )}
    </StyledButton>
  );
};

Button.displayName = "Button";
