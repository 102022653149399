import { connect } from "react-redux";
import {
  amITheSessionOwner,
  getMessageHistory,
  getSessionId,
  getConnectionStatus
} from "store/chatconnection/selectors";
import { IParticipant } from "types/chatTypes";
import Timeline from "components/Timeline/Timeline";
import { CHAT_SET_TIMELINE_HISTORY } from "store/chatconnection/types";
import { getMyRole } from "Identity/selectors";

const getAllParticipants = (
  participants: Record<string, IParticipant>
): IParticipant[] => Object.values(participants);

function mapStateToProps(state) {
  const sessionId = getSessionId(state);
  const isSessionOwner = amITheSessionOwner(state);
  const allParticipants = getAllParticipants(state.chatconnection.participants);
  const { messages, typingMessage } = getMessageHistory(state);
  const connectionStatus = getConnectionStatus(state);
  const roles = getMyRole(state);

  return {
    sessionId,
    isSessionOwner,
    allParticipants,
    messageHistory: messages,
    typingMessage,
    connectionStatus,
    roles
  };
}

const mapDispatchToProps = (dispatch) => ({
  setChatTimeline: (timeline) => {
    dispatch({
      type: CHAT_SET_TIMELINE_HISTORY,
      data: timeline
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
