import styled from "styled-components";
import { Button, Badge } from "darker-matter";
import { linkButtonMixin } from "shared/mixins/buttons";
import { css } from "@styled-system/css";

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

export const RetryButton = styled(Button)(linkButtonMixin);

export const StyledBadge = styled(Badge)(
  css({
    display: "block",
    maxWidth: "80%",
    boxShadow: "inset 0 0 0 2px transparent, inset 0 0 0 2px #017A67"
  })
);
