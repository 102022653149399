// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React, { useState, useRef } from "react";
import { Box, Brand, Navigation, Icon, Text } from "darker-matter";
import styled from "styled-components";
import { rem } from "polished";
import {
  IBoxComponentProps,
  IButtonComponentProps,
  INavigation
} from "darker-matter/build/shared-types";
import { buttonResetStyleProps } from "styles/shared.ts";

import {
  baseStyles,
  defaultBrandStyles,
  brandContainerStyles,
  menuPanelStyles,
  menuTriggerStyles,
  scrollbarStyles
} from "./styles.ts";

import Menu from "./components/Menu/Menu.tsx";
import UserMenu from "./components/UserMenu/UserMenu.tsx";
import useOnClickOutside from "./hooks/useOnClickOutside.tsx";

interface ITopBarProps extends IBoxComponentProps {
  /**
   * Leave default to show the standard unseen branding, pass a Component to use an alternative branding, set null to show no branding at all
   */
  branding?: null | React.ReactElement;
  /**
   * Pass an array of menu items to display; or pass a custom component (using the `<TopBar.Menu>` and `<TopBar.MenuItem>` if required)
   */
  menu?: INavigation;
  /**
   * On smaller screens you're unlikely to be able to fit the entire menu in the viewport. Collapsed layout allows you to choose which layout to show at which breakpoint.
   */
  collapsed?: boolean;
  /**
   * Payer name displayed on the panel navigation
   */
  payerName?: string;
  /**
   * Enable the drop shadow
   */
  shadow?: boolean;
  /**
   * Content to be displayed in the user menu area - on the right-hand end;
   */
  userMenu?: React.ReactElement;
  role?: string;
}

interface IStyledMenuPanel extends IBoxComponentProps {
  $menuPanelOpen: boolean;
}

const StyledTopBar = styled(Box)<ITopBarProps>(baseStyles);
const StyledBrandContainer = styled(Box)(brandContainerStyles);
const StyledDefaultBrand = styled(Brand)(defaultBrandStyles);
const StyledMenuTrigger = styled("button")<IButtonComponentProps>(
  buttonResetStyleProps,
  menuTriggerStyles
);

const StyledMenuPanel = styled(Box)<IStyledMenuPanel>(
  (props) => ({
    transform: props.$menuPanelOpen
      ? "translate3d(0,0, 0)"
      : "translate3d(-100%, 0,0)"
  }),
  menuPanelStyles,
  scrollbarStyles
);

/**
 * TopBar is the header used in many applications to show branding and sometimes site-wide navigation.
 */
export const TopBar: React.FC<ITopBarProps> & {
  Menu: typeof Menu;
  UserMenu: typeof UserMenu;
} = ({
  branding,
  menu,
  collapsed = false,
  shadow = true,
  payerName,
  userMenu,
  ...props
}) => {
  const BrandMarkup = React.isValidElement(branding) ? (
    branding
  ) : (
    <StyledDefaultBrand />
  );
  const rootRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuPanelOpen, setMenuPanel] = useState(false);
  const { items, activeItem, maxCount } = menu || {};
  useOnClickOutside([rootRef, buttonRef], () => setMenuPanel(false));

  const collapsableItems = React.useMemo(
    () =>
      collapsed
        ? items?.map(({ onClick, ...others }) => ({
            ...others,
            onClick: (e: React.SyntheticEvent) => {
              if (onClick !== undefined) {
                onClick(e);
              }
              setMenuPanel(false);
            }
          }))
        : items,
    [items, collapsed, setMenuPanel]
  );

  return (
    <header>
      <StyledTopBar
        variant={collapsed}
        gridTemplateColumns={
          collapsed
            ? "[menuButton] auto [branding] 1fr [altMenus] auto"
            : "[branding] auto [menu] 1fr [altMenus] auto"
        }
        boxShadow={shadow ? "elevation1" : null}
        {...props}
      >
        {collapsed && (
          <StyledMenuTrigger
            display="block"
            onClick={() => setMenuPanel(!menuPanelOpen)}
            aria-label="Show menu"
            aria-description="Show menu"
            role="button"
            ref={buttonRef}
          >
            <Icon name="menu" accessibilityTitle="Menu icon" />
          </StyledMenuTrigger>
        )}
        {branding && (
          <StyledBrandContainer
            justifySelf={collapsed ? "center" : "start"}
            mr={collapsed ? null : 5}
            width={rem(88)}
          >
            {BrandMarkup}
          </StyledBrandContainer>
        )}
        {!collapsed && menu && (
          <Menu items={items} activeItem={activeItem} maxCount={maxCount} />
        )}
        {userMenu}
      </StyledTopBar>
      {collapsed && (
        <div ref={rootRef}>
          <StyledMenuPanel $menuPanelOpen={menuPanelOpen}>
            <StyledMenuTrigger
              onClick={() => setMenuPanel(!menuPanelOpen)}
              aria-label="Close menu"
              aria-describedby="Close menu"
              role="button"
              mt={rem(28)}
              ml={5}
              mb={3}
            >
              <Icon
                name="cross"
                accessibilityTitle="Close icon"
                $color="inherit"
              />
            </StyledMenuTrigger>
            {branding && (
              <Box mx="auto" mb={4} width={rem(117)}>
                {BrandMarkup}
              </Box>
            )}
            {payerName && (
              <Text as="h1" textStyle="medium" mx={2} mb={5} textAlign="center">
                {payerName}
              </Text>
            )}
            {menu && (
              <Navigation
                items={collapsableItems}
                activeItem={activeItem}
                maxCount={maxCount}
              />
            )}
          </StyledMenuPanel>
        </div>
      )}
    </header>
  );
};

TopBar.Menu = Menu;
TopBar.UserMenu = UserMenu;
TopBar.displayName = "TopBar";

export default TopBar;
