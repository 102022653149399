import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { handleRestoreUrl } from "./utils/common";

import loadConfig from "./config";

handleRestoreUrl();

loadConfig.then((config) => {
  ReactDOM.render(
    <App config={config} />,

    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
