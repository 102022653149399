import css from "@styled-system/css";

export const baseStyles = css({
  display: "block",
  pt: 2,
  pb: 1,
  "& + &": {
    pt: 1,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "neutral.dark"
  },
  button: {
    transition: "background-color 0.2s, box-shadow 0.2s"
  },
  "button:hover": {
    backgroundColor: "extended1.light"
  },
  "button:active": {
    backgroundColor: "extended1.dark"
  },
  "button:focus": {
    borderRadius: "4px",
    boxShadow: `inset 0 0 0 2px transparent, inset 0 0 0 2px #017A67`
  }
});

export const actionStyles = css({
  color: "inherit",
  display: "flex",
  alignItems: "center",
  width: "100%",
  py: 1,
  px: 4,
  mb: 1,
  "&:hover": {
    bg: "extended1.lighter",
    cursor: "pointer",
    textDecoration: "none"
  },
  "&:focus": {
    outline: "1px blue"
  },
  "&[disabled]": {
    opacity: "0.5",
    pointerEvents: "none"
  }
});
