import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTelemetry } from "ieso-telemetry";
import { toast } from "react-hot-toast";
import { httpStatusCodeFromError } from "utils/errors.ts";
import { IMessageAttachment } from "types/chatTypes.ts";
import Attachment from "components/Attachment/Attachment.tsx";
import { TELEMETRY_KEYS } from "config/telemetry.ts";
import { useUserId } from "hooks/useUserId.ts";
import { getTherapyRoomId } from "utils/common";
import ToastContent from "./ToastContent.tsx";
import useAttachments from "../hooks/useAttachments.ts";
import {
  BottomPanelContainer,
  SendButtonContainer,
  Editor,
  HiddenInput,
  TextArea,
  Toolbar,
  SendButton,
  AttachmentsContainer,
  RemoveAttachButton,
  ToolbarButton
} from "./styles.ts";
import AllowedExtensions, { allowedExtensions } from "./AllowedExtensions.tsx";
import EmojiPicker, { Emoji } from "./EmojiPicker.tsx";

interface IBottomPanelProps {
  sessionId: string;
  isSendButtonDisabled: boolean;
  onSend: (
    message: string,
    attachments: IMessageAttachment[] | undefined
  ) => void;
  onChange: () => void;
}

// We currently get 422 (entity is not processable) instead of 415 (unsupported media type)
// when uploading an unsupported file type. 415 might be the correct one instead of a generic
// "validation failed" status code so we want to support both here (in case they'll fix/change this API's side).
const KNOWN_ADD_ATTACHMENT_ERRORS = {
  413: { title: "File too large" },
  415: {
    title: "Unsupported file type",
    description: "Sorry, we're unable to attach that file type."
  },
  422: {
    title: "Unsupported file type",
    description: "Sorry, we're unable to attach that file type."
  }
};

const BottomPanel = React.forwardRef<HTMLDivElement, IBottomPanelProps>(
  ({ sessionId, isSendButtonDisabled, onSend, onChange }, ref) => {
    const userId = useUserId();
    const telemetry = useTelemetry();
    const fileInputRef = useRef(null);
    const toolbarRef = useRef<HTMLDivElement>();
    const [lineCount, setLineCount] = useState(1);
    const [messageBody, setMessageBody] = useState("");
    const [selection, setSelection] = useState<{
      start: number;
      end: number;
    }>();
    const {
      attachments,
      addAttachment,
      removeAttachment,
      clearAttachments
    } = useAttachments(sessionId);
    const therapyRoomId = getTherapyRoomId();

    const messageFieldRef = useRef<HTMLTextAreaElement>();

    useEffect(
      () =>
        // If we're unmounted without sending these attachments then
        // we have to remove them from the server.
        () => {
          attachments.forEach((x) => removeAttachment.perform(x.id));
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    // We have to clear the input after sending a message
    const sendMessageFn = useCallback(() => {
      const attachmentsToSend = attachments.map((a) => ({
        id: a.id,
        file_name: a.name,
        content_type: a.type
      }));
      onSend(messageBody, attachmentsToSend);
      telemetry.trackEvent(TELEMETRY_KEYS.CLICKED_SEND_BUTTON, {
        userId,
        therapyRoomId
      });
      telemetry.trackEvent(TELEMETRY_KEYS.MESSAGE_SENT, {
        userId,
        therapyRoomId
      });
      setMessageBody("");
      clearAttachments.perform();
      setLineCount(1);
      setSelection(null);
      messageFieldRef.current.focus();
    }, [
      messageBody,
      clearAttachments,
      setSelection,
      messageFieldRef.current,
      onSend
    ]);

    useEffect(() => {
      if (removeAttachment.error) {
        toast.custom((t) => (
          <ToastContent
            appearance="error"
            title="Remove Attachment"
            error={removeAttachment.error}
            onDismiss={() => toast.dismiss(t.id)}
          />
        ));
      }
    }, [removeAttachment.error]);

    const hasMessageToSend =
      !!messageBody?.trim()?.length ||
      (attachments.length > 0 &&
        !attachments.some((attachment) => attachment.loading));

    const isSendDisabled = useMemo(
      () => attachments.some((a) => a.loading) || removeAttachment.isLoading,
      [attachments, removeAttachment.isLoading]
    );

    const handleAddAttachment = () => {
      fileInputRef.current?.click();
    };

    // If the editor is at the bottom of the page then the input with focus
    // won't move but the toolbar will disappear outside of view. With this
    // we force the toolbar to become visible (effectively moving the input up)
    // when the user starts typing.
    useEffect(() => {
      if (hasMessageToSend) {
        toolbarRef.current?.scrollIntoView?.();
      }
    }, [hasMessageToSend, lineCount]);

    const onFileChange = useCallback(
      (e) => {
        Array.from(e.target.files).forEach((file: File) => {
          if (attachments.some(({ file: { name } }) => name === file.name))
            return;

          addAttachment.perform(file).catch((error) => {
            toast.custom((t) => (
              <ToastContent
                appearance="error"
                title="Unable to attach file"
                knownHttpErrors={KNOWN_ADD_ATTACHMENT_ERRORS}
                error={error}
                onDismiss={() => toast.dismiss(t.id)}
              >
                {[415, 422].includes(httpStatusCodeFromError(error)) && (
                  <AllowedExtensions py={2} />
                )}
              </ToastContent>
            ));
          });
        });

        e.target.value = "";
      },
      [addAttachment, attachments]
    );

    const handleBlur = useCallback((event) => {
      const {
        selectionStart,
        selectionEnd
      } = event.target as HTMLTextAreaElement;

      setSelection({
        start: selectionStart,
        end: selectionEnd
      });
    }, []);

    const handleEmojiSelect = useCallback(
      ({ native: emoji }: Emoji) => {
        setMessageBody((m) => {
          const position = m.length ? m.length : 0;
          const { start, end } = selection || {
            start: position,
            end: position
          };

          return m.slice(0, start) + emoji + m.slice(end);
        });

        setSelection(null);
        messageFieldRef.current.focus();
      },
      [selection]
    );

    return (
      <BottomPanelContainer ref={ref}>
        <Editor
          aria-label="Chat Editor"
          // eslint-disable-next-line jsx-a11y/aria-props
          aria-description={`This is the chat editor. You can type your message in the text area. Press "Enter" 
          while holding "Shift" to send your message. 
          The send button will be enabled when there is a message to send. 
          You can also attach files, which will appear below the message input. 
          Each attachment has a "Remove" button to delete it.`}
        >
          <TextArea
            aria-label="Chat"
            aria-description="Type a message"
            forwardRef={messageFieldRef}
            id="chatInput"
            multiline={lineCount}
            placeholder="Type a message"
            value={messageBody}
            spellCheck
            onBlur={handleBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange();
              setMessageBody(e.currentTarget.value);
              setLineCount(
                (e.currentTarget.value.match(/\n/g) || []).length + 1
              );
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
              if (
                e.key === "Enter" &&
                e.shiftKey &&
                hasMessageToSend &&
                !isSendButtonDisabled &&
                !isSendDisabled
              ) {
                sendMessageFn();
              } else {
                e.key === "Enter" && e.shiftKey && e.preventDefault();
              }
            }}
          />
          {hasMessageToSend && (
            <SendButtonContainer>
              <SendButton
                id="sendMessageButton"
                size="large"
                appearance="tertiary"
                icon="send"
                ariaLabel="Send"
                ariaDescription="Send the message"
                ariaDisabled={isSendButtonDisabled || isSendDisabled}
                disabled={isSendButtonDisabled || isSendDisabled}
                onClick={sendMessageFn}
              />
            </SendButtonContainer>
          )}
          <AttachmentsContainer>
            {attachments.map((attachment) => (
              <Attachment
                key={attachment.id}
                mb={2}
                mr={2}
                aria-label={`Attachment ${attachment.name}`}
                fileName={attachment.name}
                fileType={attachment.type}
                loading={attachment.loading}
                muted
              >
                <RemoveAttachButton
                  ariaLabel="Remove attachment"
                  ariaDescription={`Remove ${attachment.id}`}
                  appearance="link"
                  loading={attachment.loading}
                  onClick={() => removeAttachment.perform(attachment.id)}
                >
                  Remove
                </RemoveAttachButton>
              </Attachment>
            ))}
          </AttachmentsContainer>
        </Editor>
        <Toolbar
          ref={toolbarRef}
          aria-label="Chat Toolbar"
          aria-controls="chatInput sendMessageButton"
        >
          <HiddenInput
            id="fileInput"
            type="file"
            multiple
            aria-hidden="true"
            tabIndex={-1}
            ref={fileInputRef}
            accept={`image/*, audio/*, video/*, text/*, application/pdf, .docx, .doc, ${allowedExtensions}`}
            onChange={onFileChange}
          />
          <ToolbarButton
            id="add-attachment"
            role="button"
            appearance="tertiary"
            icon="attachment"
            ariaLabel="Add attachment"
            ariaDescription="Add an attachment"
            onClick={handleAddAttachment}
          />
          <EmojiPicker onSelect={handleEmojiSelect} />
        </Toolbar>
      </BottomPanelContainer>
    );
  }
);

BottomPanel.displayName = "BottomPanel";

export default BottomPanel;
