/* eslint-disable camelcase */
export type TEventType =
  | "AudioRecording"
  | "Sentence"
  | "typing"
  | "ScheduledSessionStarted"
  | "ScheduledSessionEnded"
  | "Join"
  | "Leave"
  | "Finish";

export interface ICommonTimelineEvent {
  event_guid: string;
  event_type: TEventType;
  time_utc: string;
}

export type IMessage = ICommonTimelineEvent & {
  attachments?: IMessageAttachment[];
  content: string;
  identity_guid: string;
  sanitizedContent: string;
  transit_status?: "failed" | "sending" | "maybe" | "sent";
  event_id: number;
  deletedUtc?: Date;
};

export interface IParticipant {
  id: string;
  fullname: string;
  identity_guid: string;
  is_online: boolean;
  is_owner: boolean;
  is_typing: boolean;
  is_removed: boolean;
}

export enum SessionAvailability {
  None = "",
  Available = "Available",
  UnknownClinitian = "UnknownClinitian",
  UnknownPatient = "UnUnknownPatientknown",
  Joinable = "Joinable",
  JoinableAsSupervisor = "JoinableAsSupervisor",
  NoLongerHaveAccess = "NoLongerHaveAccess",
  AwaitingClinician = "AwaitingClinician"
}

export interface IMessageAttachment {
  id: string;
  file_name: string;
  content_type: string;
}

export interface IRecordingsComposition {
  compositionGuid: string;
  compositionStatus: string;
  errorMessage?: string;
  createdUtc: string;
  recordingStartedUtc: string;
  recordingEndedUtc?: string;
  size?: number;
  durationTicks?: number;
}

export type IRecordingTimelineEvent = ICommonTimelineEvent &
  IRecordingsComposition;

export type ITimelineEvent = IMessage | IRecordingTimelineEvent;
