// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React from "react";
import styled from "styled-components";
import { hideVisually } from "polished";

import { IButtonProps } from "darker-matter/build/components/Button";
import { Button } from "../Button/button.tsx";
import { magnitudeVariant } from "./styles.ts";

export interface IIconButtonProps
  extends Omit<IButtonProps, "label" | "fullWidth" | "segment" | "children"> {
  ariaDescription?: string;
}

export const StyledA11yLabel = styled("span")(hideVisually());

const StyledIconButton = styled(Button).withConfig({
  shouldForwardProp: (prop: string) => prop !== "iconButtonVariant"
})<IIconButtonProps & { iconButtonVariant: "small" | "medium" | "large" }>(
  magnitudeVariant
);

/**
 * IconButton is Circular “icon only” button that compose the Button Component with additional Icon props and renders (will be added as soon as they will be needed)
 */
export const IconButton: React.FC<IIconButtonProps> = ({
  magnitude = "medium",
  ...props
}) => (
  <StyledIconButton
    {...props}
    magnitude={magnitude}
    iconButtonVariant={magnitude}
  />
);

IconButton.displayName = "IconButton";

export default IconButton;
