import { css } from "@styled-system/css";

export const containerStyles = css({
  display: "inline-flex"
});

export const popperStyles = css({
  zIndex: "popover"
});

export const popoverStyles = css({
  borderRadius: "md",
  bg: "neutral.lighter",
  boxShadow: "elevation3",
  overflow: "hidden",
  position: "relative",
  zIndex: "popover"
});
