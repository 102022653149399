/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IIconProps } from "darker-matter/build/components/Icon/Icon";
import {
  DetailsContainer,
  Header,
  HeaderContent,
  HeaderIcon
} from "./styles.ts";

interface ICollapsibleProps
  extends Partial<Omit<HTMLDivElement, "children" | "onclick">> {
  appearance?: "normal" | "link";
  disabled?: boolean;
  header: string | React.ReactNode;
  open?: boolean | null;
  children: React.ReactNode;
  iconSet?: "add-minimise" | "add-cross" | "up-down" | "right-down";
  iconPosition?: "hidden" | "near" | "far";
  ariaLabel?: string;
  ariaDescription?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  onToggle?: (e: React.SyntheticEvent & { expanded: boolean }) => void;
}

export const Collapsible: React.FC<ICollapsibleProps> = (props) => {
  const {
    appearance,
    disabled,
    header,
    open,
    iconSet,
    iconPosition,
    children,
    onToggle,
    ariaLabel = "Click to expand",
    ariaDescription,
    ...containerProps
  } = props;

  const id = props.id || "collapsible";
  const [expanded, setExpanded] = useState(open);
  useEffect(() => {
    if (typeof open === "boolean" && open !== expanded) {
      setExpanded(open);
    }
  }, [expanded, open]);

  const isContentVisible = open || expanded;

  const toggleContentVisibility = useCallback(
    (e: React.SyntheticEvent) => {
      if (disabled) {
        return;
      }

      if (onToggle) {
        onToggle({ ...e, expanded: !isContentVisible });
        if (e.isDefaultPrevented()) {
          return;
        }
      }

      if (typeof open !== "boolean") {
        setExpanded((x) => !x);
      }
    },
    [disabled, isContentVisible, onToggle, open]
  );

  const handleHeaderKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === " ") {
        toggleContentVisibility(e);
      }
    },
    [toggleContentVisibility]
  );

  const icon: IIconProps["name"] | "" = useMemo(() => {
    switch (iconSet) {
      case "add-cross":
        return isContentVisible ? "cross" : "add";
      case "add-minimise":
        return isContentVisible ? "minimise" : "add";
      case "up-down":
        return isContentVisible ? "caret-up" : "caret-down";
      case "right-down":
        return isContentVisible ? "caret-down" : "caret-right";
      default:
        return "";
    }
  }, [isContentVisible, iconSet]);

  return (
    <div {...(containerProps as any)}>
      <Header
        id={`${id}--header`}
        as={disabled ? "section" : "button"}
        tabIndex={disabled ? -1 : 0}
        appearance={appearance}
        disabled={!!disabled}
        expanded={isContentVisible}
        aria-label={ariaLabel}
        aria-expanded={isContentVisible}
        // TODO: Update a11y linter
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-description={ariaDescription}
        aria-controls={`${id}--content`}
        onClick={toggleContentVisibility}
        onKeyPress={handleHeaderKeyPress}
      >
        {icon && <HeaderIcon name={icon} iconPosition={iconPosition} />}
        <HeaderContent>{header}</HeaderContent>
      </Header>
      <DetailsContainer
        id={`${id}--content`}
        hidden={!isContentVisible}
        role="region"
        aria-live="polite"
      >
        {isContentVisible && children}
      </DetailsContainer>
    </div>
  );
};
