import React from "react";
import { Box, IBoxComponentProps, Text } from "darker-matter";

export const allowedExtensions = [
  "avi",
  "bmp",
  "csv",
  "doc",
  "docx",
  "gif",
  "jpeg",
  "jpg",
  "mp3",
  "odp",
  "odt",
  "pdf",
  "png",
  "ppt",
  "pptx",
  "rtf",
  "tif",
  "tiff",
  "txt",
  "xls",
  "xlsx"
].join(", .");

const AllowedExtensions: React.FC<IBoxComponentProps> = (props) => (
  <Box {...props}>
    <Text>The following files are supported:</Text>
    <Text>{allowedExtensions}</Text>
  </Box>
);

AllowedExtensions.displayName = "AllowedExtensions";

export default AllowedExtensions;
