import {
  compose,
  space,
  typography,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow
} from "styled-system";

const all = compose(
  space,
  typography,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow
);

export const props = all.propNames;

export const regex = new RegExp(`^(${props.join("|")})$`);

const shouldForwardProp = (prop: string | number): boolean => {
  return !regex.test(String(prop));
};

export default shouldForwardProp;
