// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React from "react";
import styled from "styled-components";
import { Box, Text } from "darker-matter";
import { coreStyleFeatures } from "styles/shared-style-props.ts";

import { IActionListProps, IActionListSection } from "./types.ts";
import { baseStyles } from "./styles.ts";
import { IActionListButton, ActionButton as Button } from "./ActionButton.tsx";
import { IActionListLink, Link } from "../Link/Link.tsx";

export const StyledActionList = styled(Box)<IActionListProps>(
  baseStyles,
  coreStyleFeatures
);

/**
 * ActionList is a sequence of items to be displayed as a vertical menu
 */
export const ActionList: React.FC<IActionListProps> = ({
  items,
  sections = [],
  onItemClick,
  ...props
}) => {
  let finalSections: IActionListSection[] = [];
  if (items) {
    finalSections = [{ items }, ...sections];
  } else if (sections) {
    finalSections = sections;
  }
  return finalSections ? (
    <>
      {finalSections.map((section, index) => {
        return (
          <StyledActionList {...props} key={section.heading || index}>
            {section.heading && (
              <Text mb={1} py={1} px={4} textStyle="small" $color="ink.light">
                {section.heading}
              </Text>
            )}
            {section.items.length > 0 &&
              section.items.map((c, i) => {
                const linkedItem = c as IActionListLink;
                const buttonedItem = c as IActionListButton;

                const linkClick = (e: any) => {
                  onItemClick?.();
                  linkedItem.onClick && linkedItem.onClick(e);
                };
                const buttonClick = (e: any) => {
                  onItemClick?.();
                  buttonedItem.onClick && buttonedItem.onClick(e);
                };

                return linkedItem.url ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    key={linkedItem.id || i}
                    ariaLabel={linkedItem.ariaLabel}
                    ariaDescription={linkedItem.ariaDescription}
                    destructive={linkedItem.destructive}
                    external={linkedItem.external}
                    onClick={linkClick}
                    id={linkedItem.id}
                    tabIndex={-1}
                    label={linkedItem.label}
                    icon={linkedItem.icon}
                    disabled={linkedItem.disabled}
                    url={linkedItem.url}
                  />
                ) : (
                  <Button
                    key={buttonedItem.id || i}
                    ariaLabel={buttonedItem.ariaLabel}
                    ariaDescription={buttonedItem.ariaDescription}
                    destructive={buttonedItem.destructive}
                    onClick={buttonClick}
                    id={buttonedItem.id}
                    tabIndex={-1}
                    label={buttonedItem.label}
                    icon={buttonedItem.icon}
                    disabled={buttonedItem.disabled}
                  />
                );
              })}
          </StyledActionList>
        );
      })}
    </>
  ) : null;
};

ActionList.displayName = "ActionList";

export default ActionList;
