/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export interface ICredentials {
  readonly token?: string;
  readonly userId?: string;
}

export const useCredentials = (): ICredentials => {
  const [accessToken, setAccessToken] = useState("");
  const { getAccessTokenSilently, user } = useAuth0();
  const userId = user?.["https://thinkwell.co.uk/ieso_user_id"];

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAccessToken();
  }, []);

  return { token: accessToken, userId };
};
