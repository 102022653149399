import css from "@styled-system/css";
import { rem } from "polished";

export const baseStyles = (props) =>
  css({
    display: "flex",
    bg: props.muted ? "neutral.light" : "neutral.lighter",
    transition: ".3s",
    position: "relative",
    fontSize: rem(16),
    lineHeight: rem(24),
    width: "inherit"
  });

export const fileTypeContainerStyles = css({
  display: "flex",
  alignItems: "center",
  bg: "extended1.main"
});

export const contentStyles = css({
  flex: "1 1 auto",
  px: "md",
  py: "sm",
  width: "inherit"
});

export const ellipsisStyles = css({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
});
