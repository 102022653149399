import React, { FC, ReactNode } from "react";
import { Box } from "darker-matter";

const Layout: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight="100%"
    maxHeight="100%"
    height="100%"
  >
    {children}
  </Box>
);

export default Layout;
