import React, { FC, useCallback } from "react";
import { Box, Text } from "darker-matter";
import { CONNECTION_STATUS_STRINGS } from "store/chatconnection/consts";
import { Container, RetryButton, StyledBadge } from "./styles.ts";

export const statusMessage = {
  Disconnected:
    "Your computer seems to be offline at the moment. We'll try to connect you automatically, or you can",
  Reconnected: "Reconnected"
};

interface IConnectionStatusProps {
  isReconnected: boolean;
  connectionStatus: string;
  shouldShowConnectionStatus: boolean;
  showReconnectButton: boolean;
  onReconnectClick: () => void;
}

const ConnectionStatus: FC<IConnectionStatusProps> = ({
  isReconnected,
  connectionStatus,
  shouldShowConnectionStatus,
  showReconnectButton,
  onReconnectClick
}) => {
  const getStatusMessage = useCallback((): string => {
    if (isReconnected) {
      return statusMessage.Reconnected;
    }

    if (connectionStatus === CONNECTION_STATUS_STRINGS.Disconnected) {
      return statusMessage.Disconnected;
    }

    return connectionStatus;
  }, [isReconnected, connectionStatus]);

  if (shouldShowConnectionStatus) {
    return (
      <Box
        position="fixed"
        width="100%"
        display="flex"
        justifyContent="center"
        zIndex={10}
      >
        <StyledBadge appearance="warning">
          <Container>
            <Text as="span">{getStatusMessage()}</Text>
            {showReconnectButton && (
              <RetryButton
                appearance="link"
                display="inline"
                mx={1}
                onClick={onReconnectClick}
              >
                try now
              </RetryButton>
            )}
          </Container>
        </StyledBadge>
      </Box>
    );
  }

  return null;
};

export default ConnectionStatus;
