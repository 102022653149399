function getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
export function formatDate(date: Date): string {
  const day = date.getDate();
  const month = Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  const year = date.getFullYear();

  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  return formattedDate;
}

export function isSameDayAsPrevious(
  currentMessageTimestamp: string,
  previousMessageTimestamp: string
): boolean {
  let previousMessageDate;

  const currentMessageDate = new Date(currentMessageTimestamp);

  if (previousMessageDate !== null) {
    previousMessageDate = new Date(previousMessageTimestamp);
  }
  if (!previousMessageDate) {
    return false;
  }
  return (
    previousMessageDate.getFullYear() === currentMessageDate.getFullYear() &&
    previousMessageDate.getMonth() === currentMessageDate.getMonth() &&
    previousMessageDate.getDate() === currentMessageDate.getDate()
  );
}
