// Throttling enforces a maximum number of times a function can be called over time. As in "execute this function at most once every 100 milliseconds."
// only do if the last event was more than 'wait' ms ago.
function throttleNotify(func, millisecondsToWaitBetweenRuns) {
  let timeLastRun;
  return (...args) => {
    const thisTime = new Date(Date.now());
    if (
      !timeLastRun ||
      thisTime - timeLastRun > millisecondsToWaitBetweenRuns
    ) {
      timeLastRun = thisTime;
      func(...args);
    }
  };
}

export default throttleNotify;
