import React from "react";
import { Text } from "darker-matter";
import { formatDate } from "./dateTimeFormatter.ts";

interface TimestampProps {
  timeUtc: string;
}

const Timestamp: React.FC<TimestampProps> = ({ timeUtc }) => {
  const formattedDate = formatDate(new Date(timeUtc));

  return <Text appearance="muted">{formattedDate}</Text>;
};

export default Timestamp;
