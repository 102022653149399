import { useMutation } from "@tanstack/react-query";
import { apiDelete, apiPost } from "ieso-api-helper";
import { ICredentials, useCredentials } from "hooks/useCredentials.ts";
import deletedEventApiUrls from "./apiUrls.ts";

const addDeletedEvent = async (eventId: number, credentials: ICredentials) => {
  const apiUrl = await deletedEventApiUrls.addDeletedEvent(eventId);
  return apiPost(apiUrl, eventId, { ...credentials }).catch((error) => {
    throw error.response;
  });
};

export const useAddDeletedEventApi = (
  eventId: number,
  handleSuccess: () => void,
  handleError: () => void
) => {
  const credentials = useCredentials();
  const mutationFn = () => addDeletedEvent(eventId, credentials);

  return useMutation(mutationFn, {
    onSuccess: handleSuccess,
    onError: handleError
  });
};

const removeDeletedEvent = async (
  eventId: number,
  credentials: ICredentials
) => {
  const apiUrl = await deletedEventApiUrls.removeDeletedEvent(eventId);
  return apiDelete(apiUrl, { ...credentials }).catch((error) => {
    throw error.response;
  });
};

export const useRemoveDeletedEventApi = (eventId: number) => {
  const credentials = useCredentials();
  const mutationFn = () => removeDeletedEvent(eventId, credentials);

  return useMutation(mutationFn);
};
