/* eslint-disable camelcase */
import React, { FC } from "react";
import { IMessageAttachment } from "types/chatTypes.ts";
import Attachment from "components/Attachment/Attachment.tsx";
import { useTelemetry } from "ieso-telemetry";
import { useUserId } from "hooks/useUserId.ts";
import { TELEMETRY_KEYS } from "config/telemetry.ts";
import { getTherapyRoomId } from "utils/common";
import { AttachmentButton } from "./styles.ts";

interface IMessageAttachmentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  attachment: IMessageAttachment;
  disabled?: boolean;
  onDownloadAttachment: (
    attachment: IMessageAttachment,
    shouldSave?: boolean
  ) => Promise<Blob>;
}

const MessageAttachment: FC<IMessageAttachmentProps> = ({
  attachment,
  disabled,
  onDownloadAttachment
}) => {
  const telemetry = useTelemetry();
  const userId = useUserId();
  const therapyRoomId = getTherapyRoomId();
  const { id, file_name, content_type } = attachment;

  const handleAttachmentView = () =>
    onDownloadAttachment(attachment, false).then((blob) => {
      telemetry.trackEvent(TELEMETRY_KEYS.ATTACHMENT_VIEW, {
        userId,
        therapyRoomId
      });

      window.open(
        URL.createObjectURL(new Blob([blob], { type: content_type })),
        "_blank"
      );
    });

  const handleAttachmentDownload = () =>
    onDownloadAttachment(attachment).then(() => {
      telemetry.trackEvent(TELEMETRY_KEYS.ATTACHMENT_DOWNLOAD, {
        userId,
        therapyRoomId
      });
    });

  return (
    <Attachment
      key={id}
      title="Attachment"
      fileName={file_name}
      fileType={content_type}
      mb={2}
    >
      <AttachmentButton
        ariaLabel="View attachment"
        ariaDescription="View an attachment"
        role="link"
        appearance="link"
        disabled={disabled}
        mr={4}
        onClick={handleAttachmentView}
      >
        View
      </AttachmentButton>
      <AttachmentButton
        role="link"
        ariaLabel="Download attachment"
        ariaDescription="Download an attachment"
        appearance="link"
        disabled={disabled}
        onClick={handleAttachmentDownload}
      >
        Download
      </AttachmentButton>
    </Attachment>
  );
};

export default React.memo(MessageAttachment);
