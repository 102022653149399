import css from "@styled-system/css";
import { Banner, Box } from "darker-matter";
import styled from "styled-components";

export const StyledBanner = styled(Banner)(
  css({
    display: "flex",
    borderLeft: "none",
    backgroundColor: "neutral.lighter",
    boxShadow: "elevation1",
    borderRadius: "xl",
    border: "1px solid #F5F5F5",
    width: "400px"
  })
);

export const StyledBox = styled(Box)(
  css({
    wordBreak: "break-word"
  })
);
