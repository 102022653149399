import { isConnected } from "store/chatconnection/middleware/closures";

const __callbackName = "__signalRMiddlewareCallback";

/**
 * Returns boolean based on whether the passed object has a signalR callback
 * @param {object} action redux action
 */
export const hasHubMethod = (action) =>
  Object.prototype.hasOwnProperty.call(action || {}, __callbackName);

/**
 * Middleware for executing signalR callbacks on redux actions
 * @param {object} connection signalR connection
 */
export const middleware = (connection) => () => (next) => (action) => {
  executeSignalRCallback(action, connection);
  next(action);
};

/**
 * Creates a copy of your redux action and decorates with the passed callback function to execute against the signalR connection
 * @param {object} action redux action that you want to decorate
 * @param {function} callbackFunc function  that takes a signalRConnection and invokes a method on the signalR hub
 * @return {object} clone of passed redux action, decorated with passed action.
 */
export const createCopyWithCallback = (action, callbackFunc) => {
  if (action && !hasHubMethod(action) && callbackFunc) {
    const target = {};
    target[__callbackName] = callbackFunc;
    return Object.assign(target, action);
  }
  return undefined;
};

/**
 * Executes a signalR callback, does nothing if the action does not have a signalR callback.
 * @param {object} action redux action with callback
 * @param {object} signalRConnection signalR connection
 */
export function executeSignalRCallback(action, signalRConnection) {
  if (hasHubMethod(action) && isConnected(signalRConnection.connectionState)) {
    action[__callbackName](signalRConnection);
  }
}
