// *** ACTIONS ***
// This file contains all the action creator functions, try to keep
// business logic in the action creators or where necessary, middlewares.
// Thick action creators thin reducers. Is the general pattern.

// actions has a type: string, payload: data
import { CHAT_SET_TIMELINE_HISTORY } from "store/chatconnection/types";
import { postErrorToElastic } from "store/logging/actions";

import { getBearerAuthHeader, getAccessToken } from "Identity/selectors";
import { getSessionId } from "store/chatconnection/selectors";

import { getEvents } from "../../api/timeline/useEventsApi.ts";
import { getCompositions } from "../../api/timeline/useCompositionsApi.ts";
import loadConfig from "../../config/index";

// eslint-disable-next-line no-unused-vars
let config;
const configRequest = loadConfig.then((c) => {
  config = c;
});

export function getHeaders(getState) {
  return {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: getBearerAuthHeader(getState())
  };
}

// TODO: Good candidate to discuss action creators / fat actions / getState in actions
export function getSessionTranscript(retries = 3) {
  return async (dispatch, getState) => {
    await configRequest;
    const sessionId = getSessionId(getState());
    const token = getAccessToken(getState());

    try {
      const events = await getEvents(sessionId, { token });
      const compositions = await getCompositions(sessionId, { token });
      const history = [...events, ...compositions];
      history.sort((a, b) => {
        const aDate = a.recordingStartedUtc ?? a.time_utc;
        const bDate = b.recordingStartedUtc ?? b.time_utc;
        return aDate.localeCompare(bDate);
      });
      dispatch({
        type: CHAT_SET_TIMELINE_HISTORY,
        data: history
      });
      return history;
    } catch (error) {
      if (is404(error)) {
        // 404 isn't going to magically be fixed, and should've been logged already
        // so don't retry!
        return undefined;
      }

      dispatch(postErrorToElastic(["getSessionTranscript()", error].join(" ")));

      if (retries) {
        return setTimeout(
          () => dispatch(getSessionTranscript(retries - 1)),
          8000 / 2 ** retries
        );
      }
      return undefined;
    }
  };
}

function is404(error) {
  return error && error.response && error.response.status === 404;
}
