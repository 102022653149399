import { useEffect } from "react";
import { LOCAL_STORAGE_KEYS } from "../utils/localStorage";
import { handleRestoreUrl } from "../utils/common";

const handleWindowLoad = () => {
  const url = window.location.href;
  const urlOrigin = `${window.location.origin}/`;
  const storedUrl = localStorage.getItem(
    LOCAL_STORAGE_KEYS.postLogoutRedirectUrl
  );

  if (url === urlOrigin && storedUrl) {
    window.location.pathname = new URL(storedUrl).pathname;
  }
};

export const useWindowLoad = () => {
  useEffect(() => {
    window.addEventListener("focus", handleRestoreUrl);
    document.addEventListener("DOMContentLoaded", handleWindowLoad);

    return () => {
      window.removeEventListener("focus", handleRestoreUrl);
      document.addEventListener("DOMContentLoaded", handleWindowLoad);
    };
  }, []);
};
