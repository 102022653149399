/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAttachmentsApi } from "api/attachments/useAttachmentsApi";

export interface IAttachmentToUpload {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly loading: boolean;
  readonly percent: number;
  readonly file?: File;
}

const useAttachments = (sessionId: string) => {
  const [attachments, setAttachments] = useState<IAttachmentToUpload[]>([]);
  const {
    addFileMutation,
    deleteFileMutation,
    downloadAttachment
  } = useAttachmentsApi(sessionId);

  useEffect(() => {
    if (addFileMutation.isSuccess && deleteFileMutation.data) {
      setAttachments((old) =>
        old.filter((a) => a.id !== deleteFileMutation.data)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFileMutation.status]);

  const addAttachment = useCallback(
    async (file: File) => {
      const attachment = {
        id: uuidv4(),
        name: file.name,
        file,
        type: file.type,
        loading: true,
        percent: 0
      };

      setAttachments((old) => [...old, attachment]);

      const data = new FormData();
      data.append("file", attachment.file);
      return addFileMutation
        .mutateAsync({
          data,
          size: file.size,
          id: attachment.id,
          name: attachment.name,
          progressHandler: (e) => {
            const percent = Math.round((e.loaded / e.total) * 100);
            setAttachments((old) =>
              old.map((item) =>
                item.id === attachment.id ? { ...item, percent } : item
              )
            );
          }
        })
        .then((result) => {
          if (result) {
            setAttachments((old) =>
              old.map((item) =>
                item.id === result.oldId
                  ? { ...item, id: result.newId, loading: false }
                  : item
              )
            );
          } else {
            // No response means that it failed
            setAttachments((old) => old.filter((x) => x.id !== attachment.id));
          }

          return result;
        })
        .catch((error) => {
          setAttachments((old) => old.filter((x) => x.id !== attachment.id));
          throw error;
        });
    },
    [addFileMutation]
  );

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      setAttachments((old) =>
        old.map((x) => (x.id !== attachmentId ? x : { ...x, loading: true }))
      );

      deleteFileMutation.mutateAsync(attachmentId).then(() => {
        setAttachments((old) => old.filter((x) => x.id !== attachmentId));
      });
    },
    [deleteFileMutation, setAttachments]
  );

  const clearAttachments = useCallback(() => {
    setAttachments([]);
  }, []);

  return useMemo(
    () => ({
      attachments,
      addAttachment: { ...addFileMutation, perform: addAttachment },
      removeAttachment: { ...deleteFileMutation, perform: removeAttachment },
      clearAttachments: { perform: clearAttachments },
      downloadAttachment
    }),
    [
      attachments,
      addFileMutation,
      addAttachment,
      removeAttachment,
      deleteFileMutation,
      clearAttachments,
      downloadAttachment
    ]
  );
};

export default useAttachments;
