import { TELEMETRY_KEYS } from "config/telemetry.ts";
import {
  RECEIVE_NOTIFY_TYPING,
  UPDATE_SESSION_STATUS,
  SIGNAL_R_RECEIVE_EVENT,
  NOTIFY_CONNECTIONS,
  SIGNAL_R_CONNECTION_CLOSED
} from "../types";
import { getTherapyRoomId } from "../selectors";

export default (getState, dispatch, signalRMiddlewareState) => {
  const state = getState();
  const { telemetry, identity, chatconnection } = state;
  const therapyRoomId = getTherapyRoomId(state);
  const prevParticipants = chatconnection.participants;
  const userId =
    identity.access_token_decoded["https://thinkwell.co.uk/ieso_user_id"];

  signalRMiddlewareState.connection.on("ReceiveEvent", (data) => {
    // fire redux action
    dispatch({
      type: SIGNAL_R_RECEIVE_EVENT,
      model: data
    });
  });
  signalRMiddlewareState.connection.on("UserTyping", (data) => {
    // fire redux action
    dispatch({
      type: RECEIVE_NOTIFY_TYPING,
      model: data
    });
  });
  signalRMiddlewareState.connection.on("NotifyConnections", (data) => {
    const participantData = data && data.participants;
    participantData?.forEach((participant) => {
      const oldValue = prevParticipants[participant.identity_guid];
      const diffStatus = oldValue.is_online !== participant.is_online;
      const diffId = userId !== participant.identity_guid;
      if (diffStatus && diffId) {
        telemetry.trackEvent(TELEMETRY_KEYS.USER_ONLINE_STATUS, {
          userId,
          therapyRoomId,
          participant
        });
      }
    });

    // fire redux action
    dispatch({
      type: UPDATE_SESSION_STATUS,
      data
    });
    dispatch({
      type: NOTIFY_CONNECTIONS,
      data
    });
  });
  signalRMiddlewareState.connection.on("AppointmentStarted", () => {
    // fire redux action
  });
  signalRMiddlewareState.connection.on("Completed", () => {
    // fire redux action
  });
  signalRMiddlewareState.connection.onclose(() => {
    // Code to do retry.
    dispatch({ type: SIGNAL_R_CONNECTION_CLOSED });
  });
};
