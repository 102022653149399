/* eslint-disable no-useless-escape */
import sanitizeHtml from "sanitize-html";

export const urlRegex = /(\S+[.])\S+/gi;

const sanitizeMessage = (message, custom = {}) =>
  sanitizeHtml(message, {
    allowedTags: [
      "span",
      "b",
      "i",
      "em",
      "strong",
      "u",
      "a",
      "br",
      "img",
      "h6",
      "h5",
      "h4",
      "h3",
      "h2",
      "h1",
      "div",
      "ol",
      "ul",
      "li"
    ],
    allowedAttributes: {
      a: ["href", "target", "rel"]
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform("a", {
        target: "_blank",
        rel: "noreferrer noopener"
      })
    },
    ...custom.options
  });

export default sanitizeMessage;
