import { rem } from "polished";
import { variant, VariantArgs } from "styled-system";
import {
  appearanceVariant,
  baseStyles,
  segmentVariant
} from "../Button/styles.ts";

export { baseStyles, appearanceVariant, segmentVariant };

export const magnitudeVariant = variant({
  prop: "iconButtonVariant",
  variants: {
    small: {
      px: 2,
      py: 2,
      width: rem(40),
      height: rem(40)
    },
    medium: {
      px: 3,
      py: 3,
      width: rem(48),
      height: rem(48)
    },
    large: {
      px: 3,
      py: 3,
      width: rem(52),
      height: rem(52)
    }
  }
} as VariantArgs);
