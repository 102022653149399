/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from "@tanstack/react-query";
import { apiGet } from "ieso-api-helper";
import { useMemo } from "react";
import {
  IRecordingTimelineEvent,
  IRecordingsComposition
} from "types/chatTypes.ts";
import { ICredentials, useCredentials } from "hooks/useCredentials.ts";
import timelineApiUrls from "./apiUrls.ts";
import { TIMELINE_REQUESTS_STALE_TIME } from "./constants.ts";

export const useGetCompositionsApi = (
  sessionId: string
): {
  status: "error" | "success" | "loading";
  data: IRecordingTimelineEvent[];
  error: unknown;
  isLoading: boolean;
} => {
  const credentials = useCredentials();

  const { isLoading, error, status, data } = useQuery({
    queryKey: ["get-compositions", sessionId],
    enabled: !!sessionId && !!credentials.token,
    staleTime: TIMELINE_REQUESTS_STALE_TIME,
    refetchOnWindowFocus: true,
    retry: 2,
    queryFn: () => getCompositions(sessionId, credentials)
  });

  return useMemo(
    () => ({
      status,
      data,
      error,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, data]
  );
};

export const getCompositions = async (
  sessionId: string,
  credentials: ICredentials
) => {
  const apiUrl = await timelineApiUrls.getCompositions(sessionId);

  return apiGet<
    { compositions: IRecordingsComposition[] },
    IRecordingTimelineEvent[]
  >(apiUrl, {
    ...credentials,
    responseTransformation: (response) =>
      (response.compositions || []).map((c) => ({
        ...c,
        time_utc: c.recordingEndedUtc || c.recordingStartedUtc || c.createdUtc,
        event_type: "AudioRecording",
        event_guid: c.compositionGuid
      }))
  });
};
