import { useEffect, useState } from "react";
import iesoConfig from ".";

function useConfig() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const load = async () => {
      setConfig(await iesoConfig);
    };

    load();
  }, []);

  return config;
}

export function useConfigAsync() {
  return iesoConfig;
}

export default useConfig;
