/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo } from "react";
import {
  IKnownHttpErrors,
  resolveErrorMessage,
  resolveErrorTitle
} from "utils/errors";
import { Box, Toast } from "darker-matter";

/**
 * Properties for the `ToastContent` component.
 */
export interface IToastContentProps {
  /**
   * Color to indicate visual state.
   * Appearance is also added as a visually hidden element for screenreaders
   */
  appearance?: "info" | "success" | "warning" | "error";
  /**
   * Title for the toast, it usually is a generic error message. It's used
   * only if `error` is unspecified and a more appropriate title cannot be found.
   * If omitted then a generic default title is used.
   */
  title?: string;
  /**
   * Message with additional details about the error. It's used if `error`
   * is unspecified or a more appropriate error message cannot be found.
   */
  message?: string;
  /**
   * Details about the error. It could be a string, a JSON object with
   * the error details (as obtained from the API) or an `Error` object.
   * This component recognizes additional properties attached to an `Error`
   * object by some libraries and it uses them to extract a meaningful error
   * description.
   */
  error?: unknown;
  /**
   * If `message` is unspecified and `error` is present then this component
   * tries to visualise the most specific error message it can. If you set this
   * flag then it will use a generic error message instead.
   */
  unhelpful?: boolean;

  /**
   * Optional collection of known HTTP errors. Caller can set the key to an HTTP status
   * code and the value to the pair title + description to show for that specific code.
   * If this property is omitted and for _unknown_ status codes the default ones are used.
   */
  knownHttpErrors?: IKnownHttpErrors;
  /**
   * Function to be called on click "Dismiss" button
   */
  onDismiss?: () => void;
  /* Children */
  children?: React.ReactElement;
}

/**
 * Component to format a `<Toast>` message with information about an error or success.
 */
const ToastContent: React.FC<IToastContentProps> = (props) => {
  const {
    appearance,
    title,
    message,
    error,
    unhelpful,
    knownHttpErrors,
    onDismiss,
    children
  } = props;

  const toastTitle = useMemo(
    () => resolveErrorTitle(error, knownHttpErrors, title, unhelpful),
    [title, error, knownHttpErrors, unhelpful]
  );

  const toastMessage = useMemo(
    () => resolveErrorMessage(error, knownHttpErrors, message, unhelpful),
    [message, error, knownHttpErrors, unhelpful]
  );

  return (
    <Box maxWidth="300px">
      <Toast
        appearance={appearance}
        title={toastTitle}
        onDismiss={() => onDismiss?.()}
        onMouseEnter={() => {}}
        onMouseLeave={() => {}}
      >
        {children || toastMessage}
      </Toast>
    </Box>
  );
};

export default ToastContent;
