/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Description of an error.
 */
export interface IErrorDescription {
  /** The title (a short description) of the error. */
  readonly title: string;
  /** The description about an error (possibly including information on how to solve it). */
  readonly description: string;
  /** Indicates whether a second attempt might resolve the error condition. */
  readonly retriable?: boolean;
  /** A technical (and internal) error code. */
  readonly code?: string | number;
}

/**
 * Represents a collection of known HTTP errors and their required title + description.
 * Note that both fields (`title` for the toast title and `description` for its content) are optional:
 * you can specify one of them and the other will use the a default - calculated - value.
 */
export type IKnownHttpErrors = Readonly<
  Readonly<Record<number, Partial<IErrorDescription>>>
>;

const GENERIC_ERROR_TITLE = "Unable to complete requested action";
const GENERIC_ERROR_MESSAGE = "Sorry, something went wrong. Please try again.";

// These are the errors we know about and we use them when the caller doesn't know any better.
// Code "0" is returned ONLY with Axios, `fetch()` throws a plain exception for this case (and we cannot read
// the status code from the `Response`).
const KNOWN_HTTP_ERRORS = {
  0: "You're experiencing network connectivity issues, check your Internet connection and try again.",
  403: "You are not allowed to perform this action.",
  404: "Sorry, something went wrong. You may try again.",
  409: "Someone else updated the same data; if possible please refresh the page.",
  413: "You are trying to send something too big.",
  415: "Service cannot accept the type of content you are sending.",
  500: GENERIC_ERROR_MESSAGE,
  503: "Service is currently unavailable, please try again later."
};

/**
 * Resolves the _title_ (short description) of the specified error.
 * @param error - An error (JavaScript exception) or an unsuccessful HTTP response.
 * @param callerKnownHttpErrors - List of specific HTTP errors that caller knows about,
 * their values override the default text calculated by this function/
 * @param fallbackText - Text to use if the error is unknown.
 * @param unhelpful - Always returns a generic error message even if a better match is available.
 * @returns A short description of the specified error.
 */
export function resolveErrorTitle(
  error: unknown,
  callerKnownHttpErrors: IKnownHttpErrors | undefined = undefined,
  fallbackText: string | null = undefined,
  unhelpful = false
): string {
  if (typeof error === "string" || unhelpful) {
    return fallbackText ?? GENERIC_ERROR_TITLE;
  }

  // Is it a known HTTP error we know how to deal with?
  const httpStatusCode = httpStatusCodeFromError(error);
  if (httpStatusCode !== undefined && callerKnownHttpErrors) {
    const callerKnownHttpError = callerKnownHttpErrors[httpStatusCode];
    if (callerKnownHttpError?.title) {
      return callerKnownHttpError.title;
    }
  }

  return fallbackText ?? GENERIC_ERROR_TITLE;
}

/**
 * Resolves the _details_ (long description) of the specified error.
 * @param error - An error (JavaScript exception) or an unsuccessful HTTP response.
 * @param callerKnownHttpErrors - List of specific HTTP errors that caller knows about,
 * their values override the default text calculated by this function/
 * @param fallbackText - Text to use if the error is unknown.
 * @param unhelpful - Always returns a generic error message even if a better match is available.
 * @returns A long description of the specified error.
 */
export function resolveErrorMessage(
  error: any,
  callerKnownHttpErrors: IKnownHttpErrors | undefined = undefined,
  fallbackText: string | null = undefined,
  unhelpful = false
): string {
  if (typeof error === "string") {
    return error;
  }

  if (unhelpful) {
    return fallbackText ?? GENERIC_ERROR_MESSAGE;
  }

  // Is it a known HTTP error we know how to deal with?
  const httpStatusCode = httpStatusCodeFromError(error);
  if (httpStatusCode !== undefined) {
    if (callerKnownHttpErrors) {
      const callerKnownHttpError = callerKnownHttpErrors[httpStatusCode];
      if (callerKnownHttpError?.description) {
        return callerKnownHttpError.description;
      }
    }
    return KNOWN_HTTP_ERRORS[httpStatusCode] ?? GENERIC_ERROR_MESSAGE;
  }

  // Plain JS Error object?
  if (error && "message" in error && typeof error.message === "string") {
    return error.message;
  }

  return fallbackText ?? GENERIC_ERROR_MESSAGE;
}

/**
 * Extracts the HTTP status code (if present) associated with the specified error.
 * @param error - The error you want to extract HTTP status code from. It could be anything
 * but status codes are present only in `Error` returned by Axios or `Response` from `fetch()`
 * (in this case note that `fetch()` does not throw if status is not 2xx but `response.ok === false`).
 * @returns The HTTP status code associated with the specified error or `undefined` if no
 * status code can be found.
 */
export function httpStatusCodeFromError(error: unknown): number | undefined {
  if (!error || typeof error !== "object") {
    return undefined;
  }

  if (Array.isArray(error)) {
    return undefined;
  }

  const unknownValue = error as any;

  // We expect to find a response property in an Error object only for
  // errors "augmented" by Axios.
  if ("isAxiosError" in unknownValue && unknownValue.isAxiosError) {
    return unknownValue?.response?.status;
  }

  // Is it a Response object we obtained from fetch()?
  if ("status" in unknownValue && typeof unknownValue.status === "number") {
    return unknownValue.status;
  }

  return undefined;
}
