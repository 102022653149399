import React, { FC, ReactNode, useEffect, useRef } from "react";
import { IButtonProps } from "darker-matter/build/components/Button";
import { Button } from "../Button/button.tsx";
import {
  CoreModal,
  Header,
  IconButton,
  Controls,
  PrimaryButton
} from "./styles.ts";

interface IModal {
  showModal: boolean;
  maxWidth?: string;
  headerContent: string;
  children: ReactNode;
  buttonAppearance?: IButtonProps["appearance"];
  buttonLabel?: string;
  contentGap?: number;
  justifyContent?: string;
  fontWeight?: number;
  onClose?: () => void;
  onProceed?: () => void;
  onCancel?: () => void;
  dismissable?: boolean;
  loading?: boolean;
}

const focusableSelectors = `button, [href]`;
const colorConfig = {
  primary: "primary",
  destructive: "error"
};

export const Modal: FC<IModal> = ({
  showModal,
  headerContent,
  maxWidth = "720px",
  children,
  buttonAppearance,
  buttonLabel,
  contentGap,
  justifyContent,
  fontWeight,
  dismissable = true,
  loading,
  onClose,
  onProceed,
  onCancel
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const prevFocusedElement = useRef<HTMLElement | null>(null);
  const color = colorConfig[buttonAppearance] || "primary";

  useEffect(() => {
    const handleTrapFocus = (e: KeyboardEvent) => {
      if (e.key !== "Tab" || !modalRef.current) return;

      const focusableElements = modalRef.current.querySelectorAll<HTMLElement>(
        focusableSelectors
      );
      if (!focusableElements.length) return;

      const first = focusableElements[0];
      const last = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === first) {
        e.preventDefault();
        last.focus();
      } else if (!e.shiftKey && document.activeElement === last) {
        e.preventDefault();
        first.focus();
      }
    };

    if (showModal) {
      prevFocusedElement.current = document.activeElement as HTMLElement;
      const focusableElements = modalRef.current?.querySelectorAll<HTMLElement>(
        focusableSelectors
      );
      if (focusableElements.length) {
        focusableElements[0].focus();
      }
      document.addEventListener("keydown", handleTrapFocus);
    } else {
      prevFocusedElement.current?.focus();
      document.removeEventListener("keydown", handleTrapFocus);
    }

    return () => {
      document.removeEventListener("keydown", handleTrapFocus);
    };
  }, [focusableSelectors, showModal]);

  const footer = buttonLabel && (
    <Controls>
      {dismissable && (
        <Button
          appearance="secondary"
          onClick={onCancel}
          ariaLabel="Cancel"
          ariaDescription="Cancel and close modal"
        >
          Cancel
        </Button>
      )}
      <PrimaryButton
        appearance={buttonAppearance}
        onClick={onProceed}
        ariaLabel={buttonLabel}
        $color={color}
        ariaDescription="Approve action of modal"
        loading={loading}
      >
        {buttonLabel}
      </PrimaryButton>
    </Controls>
  );

  return (
    showModal && (
      <div ref={modalRef}>
        <CoreModal
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-header"
          aria-describedby="modal-content"
          showModal={showModal}
          maxWidth={maxWidth}
          onProceed={onProceed}
          onCancel={onCancel}
          footer={footer}
        >
          <Header
            id="modal-header"
            $contentGap={contentGap}
            $justifyContent={justifyContent}
            $fontWeight={fontWeight}
          >
            {headerContent}
            {onClose && (
              <IconButton
                icon="cross"
                ariaLabel="Close modal"
                appearance="secondary"
                onClick={onClose}
              />
            )}
          </Header>
          <div id="modal-content">{children}</div>
        </CoreModal>
      </div>
    )
  );
};
