import { css } from "@styled-system/css";
import { rem } from "polished";

// flexShrink default prevents Firefox collapsing topbar when used inside a flex layout.
export const baseStyles = css({
  display: "grid",
  position: "relative",
  bg: "neutral.lighter",
  height: rem(64),
  flexShrink: 0,
  gridTemplateRows: "none",
  alignItems: "center",
  px: 5
});

export const brandContainerStyles = css({
  gridArea: "branding",
  gridRow: 1,
  alignSelf: "center"
});

export const defaultBrandStyles = css({
  width: "100%",
  display: "block"
});

export const menuPanelStyles = css({
  display: "block",
  position: "fixed",
  bg: "white",
  top: 0,
  left: 0,
  height: "100vh",
  transition: "transform .15s ease-in-out",
  width: rem(300),
  boxShadow: "elevation3",
  zIndex: "menu",
  overflowY: "auto"
});

export const menuTriggerStyles = css({
  gridArea: "menuButton",
  gridRow: 1,
  width: rem(24),
  height: rem(24),
  color: "ink.main",
  "&:hover": {
    color: "ink.light"
  }
});

export const scrollbarStyles = css({
  "&::-webkit-scrollbar": {
    bg: "neutral.lighter",
    boxShadow: "inset 2px 0 6px rgba(0,0,0,.05)",
    width: rem(8)
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "999em",
    bg: "neutral.dark"
  }
});
