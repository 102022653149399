/* eslint-disable camelcase */
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import { getBearerAuthHeader, getMyId, getMyRole } from "Identity/selectors";
import { IParticipant } from "types/chatTypes.ts";
import {
  amITheSessionOwner,
  getIsSessionActive,
  getIsVideoSession,
  getSessionEndDate,
  getSessionId,
  getSessionStatus,
  getHasClinicians,
  getReadonlyMode,
  getMessageHistory,
  getConnectionStatus
} from "store/chatconnection/selectors";
import {
  SIGNAL_R_SEND_MESSAGE,
  UPDATE_IS_SESSION_ACTIVE
} from "store/chatconnection/types";
import ChatHeader from "./ChatHeader.tsx";

const getOtherParticipants = (
  participants: Record<string, IParticipant>,
  myID: string
) =>
  Object.entries(participants)
    .filter(([id, p]) => id !== myID && !p.is_removed)
    .map(([id]) => participants[id]);

const mapStateToProps = (state) => {
  const myId = getMyId(state);
  const otherParticipants = getOtherParticipants(
    state.chatconnection.participants,
    myId
  );
  const patient = Object.values(state.chatconnection.participants).find(
    (participants) => !(participants as IParticipant)?.is_owner
  );
  const isParticipant = !!state.chatconnection.participants[myId];
  const isOwner = amITheSessionOwner(state);
  const myName = state.chatconnection.participants[myId]?.fullname;
  const sessionId: string = getSessionId(state);
  const sessionStatus: string = getSessionStatus(state);
  const auth_header = getBearerAuthHeader(state);
  const isVideoSession = getIsVideoSession(state);
  const identity_guid = getMyId(state);
  const sessionEndDate = getSessionEndDate(state);
  const isSessionActive = getIsSessionActive(state);
  const hasClinicians = getHasClinicians(state);
  const readonlyMode = getReadonlyMode(state);
  const roles = getMyRole(state);
  const { messages } = getMessageHistory(state);
  const connectionStatus = getConnectionStatus(state);

  return {
    otherParticipants,
    isParticipant,
    isOwner,
    sessionId,
    sessionStatus,
    auth_header,
    messages,
    myName,
    patient,
    isVideoSession,
    identity_guid,
    sessionEndDate,
    isSessionActive,
    hasClinicians,
    readonlyMode,
    roles,
    connectionStatus
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateIsSessionActive: (isActive) => {
    dispatch({
      type: SIGNAL_R_SEND_MESSAGE,
      eventType: isActive ? "ScheduledSessionEnded" : "ScheduledSessionStarted",
      content: null,
      model: {
        event_guid: uuidv4(),
        time_utc: new Date().toUTCString()
      }
    });
    dispatch({
      type: UPDATE_IS_SESSION_ACTIVE,
      value: !isActive
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader);
