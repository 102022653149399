import {
  background,
  compose,
  system,
  space,
  layout,
  flexbox,
  grid,
  border,
  position,
  shadow,
  color,
  typography
} from "styled-system";

export const coreStyleFeatures = compose(
  background,
  space,
  color,
  layout,
  flexbox,
  shadow,
  grid,
  border,
  position,
  typography,
  system({
    // Alias color as $color
    $color: {
      property: "color", // css prop map
      scale: "colors" // theme object mapping
    }
  })
);

export const formInputFeatures = compose(background, space, layout, border);
