import loadConfig from "config";

const getConfig = async () => loadConfig;

const timelineApiUrls = {
  getEvents: (sessionId: string): Promise<string> =>
    getConfig().then(
      (config) => `${config.tss_url}/therapy/v1/sessions/${sessionId}/events`
    ),
  getCompositions: (sessionId: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/compositions`
    ),
  getAudioUri: (sessionId: string, compositionGuid: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/videocompositionaudio/${compositionGuid}`
    )
};

export default timelineApiUrls;
