import { LOCAL_STORAGE_KEYS } from "./localStorage";

export const isAppointmentOrTherapyUrl = (url) => {
  const hasAppointment = url.match(/Appointment/gi);
  const hasTherapy = url.match(/therapy/gi);
  const hasUuid = url.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gi
  );

  return (!!hasAppointment || !!hasTherapy) && !!hasUuid;
};

export const handleRestoreUrl = () => {
  const url = window.location.href;

  const urlOrigin = `${window.location.origin}/`;
  const isUrlAppointmentOrTherapy = isAppointmentOrTherapyUrl(url);

  if (url !== urlOrigin && isUrlAppointmentOrTherapy) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.postLogoutRedirectUrl, url);
  }
};

export const getTherapyRoomId = () => {
  return window.location.pathname.split("/")[2];
};
