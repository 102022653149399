import { useEffect, useLayoutEffect, useState } from "react";

interface IScreenResize {
  minWidth?: number;
  maxWidth?: number;
  callback: (arg: {
    screenSize: number;
    type: "minWidth" | "maxWidth";
  }) => void;
}

const useScreenResize = ({
  callback,
  maxWidth = Number.MAX_SAFE_INTEGER,
  minWidth = Number.MIN_SAFE_INTEGER
}: IScreenResize) => {
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < minWidth || screenWidth > maxWidth) {
        callback({
          screenSize: screenWidth,
          type: screenWidth < minWidth ? "minWidth" : "maxWidth"
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minWidth, maxWidth, callback]);
};

const useScreenSizeOnLoad = (callback: (screenSize: number) => void) => {
  useLayoutEffect(() => {
    const handleLoad = () => {
      const screenWidth = window.innerWidth;
      callback(screenWidth);
    };

    handleLoad();

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, [callback]);
};

export const useMobileView = () => {
  const [mobileView, setMobileView] = useState(false);

  useScreenResize({
    callback: async ({ type }) => {
      setMobileView(type === "minWidth");
    },
    maxWidth: 640,
    minWidth: 640
  });
  useScreenSizeOnLoad((size) => {
    setMobileView(size < 640);
  });

  return mobileView;
};
